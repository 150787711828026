import { TableEntry } from "@telia/b2x-table/dist/types/components/table/table";
import { TableManagerColumn } from "@telia/b2x-table/dist/types/components/table-column-manager/table-column-manager";
import type { Components } from "@teliads/components/dist/types/components";
import { translate } from "@telia/b2b-i18n";
import { TableHeaderSortableIconType } from "@telia/b2x-table/dist/types/components/table-header-row/table-header-sortable-icon-type";
import { SubscriptionRow } from "../interface/SubscriptionRow";

//TODO: get the enums from b2x-table -- not exported properly

enum TableCellType {
  TEXT = "text",
  TRUNCATABLE_TEXT = "truncatable-text",
  TRUNCATABLE_FLEXIBLE_WIDTH_TEXT = "truncatable-flexible-width-text",
  LINK = "link",
  SSPA_LINK = "sspa-link",
  BUTTON = "button",
  BUTTON_TEXT = "button-text",
  BADGE = "badge",
  STATUS_BADGE = "status-badge",
  CHECKBOX = "checkbox",
  TOGGLE = "toggle",
}

type SortingColumnName =
  | "NAME_MSISDN"
  | "PRODUCT"
  | "SUBSCRIPTION_REFERENCE"
  | "CURRENT_HOLDING"
  | "STATUS";
type SortDirection = "ASC" | "DSC";

interface ColumnSort {
  column: SortingColumnName;
  sortDirection: SortDirection;
}

export function createTableColumn(columnSort: ColumnSort): TableManagerColumn[] {
  return benefitColumns(columnSort).map((c) => ({
    ...c,
    title: getTranslateTableColumnTitle(c.title),
  }));
}

export function getColumnTitle(title: string): string | undefined {
  return benefitColumns().find((c) => getTranslateTableColumnTitle(c.title) === title)?.title;
}

function getTranslateTableColumnTitle(title) {
  return translate(`COLUMNS.${title}`);
}

function benefitColumns(columnsSort?: ColumnSort): TableManagerColumn[] {
  return [
    {
      title: "MANAGE",
      type: TableCellType.BUTTON_TEXT,
      isChecked: true,
      disabled: true,
    },
    {
      title: "NAME_MSISDN",
      type: TableCellType.TEXT,
      isChecked: true,
      disabled: false,
      sortable: {
        sortIconName:
          columnsSort?.column === "NAME_MSISDN" ? getSortIcon(columnsSort.sortDirection) : "sorter",
      },
      filterable: { type: "text", label: translate("COLUMNS.NAME_MSISDN") },
    },
    {
      title: "PRODUCT",
      type: TableCellType.TEXT,
      isChecked: true,
      disabled: false,
      sortable: {
        sortIconName:
          columnsSort?.column === "PRODUCT" ? getSortIcon(columnsSort.sortDirection) : "sorter",
      },
      filterable: { type: "text", label: translate("COLUMNS.PRODUCT") },
    },
    {
      title: "SUBSCRIPTION_REFERENCE",
      type: TableCellType.TEXT,
      isChecked: true,
      disabled: false,
      sortable: {
        sortIconName:
          columnsSort?.column === "SUBSCRIPTION_REFERENCE"
            ? getSortIcon(columnsSort.sortDirection)
            : "sorter",
      },
      filterable: { type: "text", label: translate("COLUMNS.SUBSCRIPTION_REFERENCE") },
    },
    {
      title: "CURRENT_HOLDING",
      type: TableCellType.TEXT,
      isChecked: true,
      disabled: false,
      sortable: {
        sortIconName:
          columnsSort?.column === "CURRENT_HOLDING"
            ? getSortIcon(columnsSort.sortDirection)
            : "sorter",
      },
      filterable: { type: "text", label: translate("COLUMNS.CURRENT_HOLDING") },
    },
    {
      title: "STATUS",
      type: TableCellType.TEXT,
      isChecked: false,
      disabled: false,
      sortable: {
        sortIconName:
          columnsSort?.column === "STATUS" ? getSortIcon(columnsSort.sortDirection) : "sorter",
      },
      filterable: { type: "text", label: translate("COLUMNS.STATUS") },
    },
  ];
}

function getSortIcon(sort: SortDirection): TableHeaderSortableIconType {
  switch (sort) {
    case "ASC":
      return "arrow-up";
    case "DSC":
      return "arrow-down";
    default:
      return "sorter";
  }
}

function makeRowsFromSubscriptions(subscriptions: SubscriptionRow[]): TableEntry[] {
  return subscriptions.map(
    (subscription, idx): TableEntry => [
      {
        content: !subscription.currentHoldings ? translate("ADD") : translate("REMOVE"),
        rowNumber: idx,
        attrs: {
          leftIcon: {
            name: !subscription.currentHoldings ? "plus" : "edit",
            size: "sm",
            allyTitle: !subscription.currentHoldings ? "Add benefit" : "Remove benefit",
            dataTestid: "handleBenefit",
          },
          variant: "expressive",
          disabled: subscription.currentHoldings?.ongoingOrder,
        } as Components.TeliaButton,
        // as TeliaButton props are all mandatory (will be fixed by voca)
      },
      subscription.msisdn ?? "",
      subscription.offeringName ?? "",
      subscription.externalReference ?? "",
      getBenefitName(subscription),
      getStatus(subscription),
    ]
  );
}

function getBenefitName(subscription: SubscriptionRow): string {
  if (!subscription.currentHoldings) return translate("NO_BENEFIT");
  const benefit = subscription.currentHoldings;
  return translate(`BENEFITS.${benefit.name}`);
}

function getStatus(subscription: SubscriptionRow) {
  if (!subscription.currentHoldings) return translate("INACTIVE");
  return subscription.currentHoldings.ongoingOrder ? translate("ONGOING") : translate("ACTIVE");
}

export { benefitColumns, makeRowsFromSubscriptions };
