import { gql } from "graphql-request";
import { currentLocale } from "@telia/b2b-i18n";

const locale = currentLocale();

export const BENEFIT_INFORMATION = gql`
  query GetTeaserWrapper {
    teaserWrapper(identifier: { key: READABLE_ID, value: "myb-benefits" }, locale: ${locale}) {
      teaserList {
        id
        richPreamble
        title
      }
    }
  }
`;

export const BENEFIT_NOT_ELIGIBLE_INFORMATION = gql`
  query GetTeaserWrapper {
    teaserWrapper(identifier: { key: READABLE_ID, value: "myb-benefits-not-eligible" }, locale: ${locale}) {
      teaserList {
        id
        richPreamble
        title
      }
    }
  }
`;
