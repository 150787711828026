<template>
  <form aria-label="form-wrapper" @submit.prevent="checkIccAndEmitSubmitEvent">
    <telia-text-input
      required
      :label="inputPlaceholder"
      @input="(event) => handleInput(event.target.value)"
      :maxlength="maxLength"
      :minlength="maxLength"
      :minlength-error-message="t('ICC_CHECK.TELIASIMCARDNUMBER_INVALID_IDENTIFIER')"
      :pattern="iccPattern"
      :pattern-error-message="t('ICC_CHECK.ICC_INVALID_FORMAT')"
      :error-message="errorMessage"
      :additional="validationMessage"
    />
  </form>
</template>

<script>
import { translateMixin } from "../../locale";
import { ValidateIcc } from "@telia/b2b-icc-handler";

export default {
  name: "icc-search",
  mixins: [translateMixin],

  props: {
    assistiveText: {
      type: String,
      default: undefined,
    },
    debounceMs: {
      type: Number,
      default: 500,
    },
    inputPlaceholder: {
      type: String,
      default: "894601xxxxxxxxxxxxxx",
    },
    maxLength: {
      type: Number,
      default: 20,
    },
    iccPrefix: {
      type: String,
      default: "894601",
    },
  },
  data: () => ({
    // icc validation
    iccNumber: "",
    iccNumberBeingChecked: "",
    iccBackendSuccess: null,
    checkingIcc: false,
    iccBackendError: null,
    iccTimeout: null,
    validationMessage: null,
    errorMessage: null,
    valid: false,
    iccHandlerInstance: null,
  }),
  mounted() {
    this.$emit("loaded", { sender: this });
    this.iccHandlerInstance = new ValidateIcc(this.iccPrefix, 20);
  },

  computed: {
    iccPattern() {
      return "^" + this.iccPrefix + "\\d+$";
    },
  },
  methods: {
    handleInput(event) {
      this.iccNumber = event;
      this.validateIccNumber(event);
    },
    validateIccNumber(number) {
      this.checkingIcc = true;
      this.validationMessage = null;
      this.iccHandlerInstance.verifyIcc(number).then((value) => {
        this.errorMessage = value.message;
        this.valid = false;

        if (value.isError === false) {
          this.errorMessage = null;
          this.validationMessage = value.message;
          this.valid = true;
          this.checkIccAndEmitSubmitEvent();
        }
      });
      this.checkingIcc = false;
    },
    checkIccAndEmitSubmitEvent() {
      if (this.valid && this.iccNumber.length) {
        this.$emit("submit", { icc: this.iccNumber });
        this.$emit("valid", { valid: this.valid });
      }
    },
  },
};
</script>
