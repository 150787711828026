const ORDER_NOTIFICATION_DURATION = 20 * 24 * 60 * 60 * 1000;
const benefitsSurveyKey = "benefits-survey-timer";
const benefitsSurveyCompletedKey = "benefits-survey-completed-block";

export const setSurveyBlockTimer = () => {
  const orderDate = new Date();
  localStorage.setItem(benefitsSurveyKey, orderDate.toString());
};
export const setSurveyCompletedBlock = () => {
  localStorage.setItem(benefitsSurveyCompletedKey, "completed");
};

const removeSurveyTimestamp = () => {
  localStorage.removeItem(benefitsSurveyKey);
};

export function shouldShowSurvey() {
  const hasCompletedBenefit = localStorage.getItem(benefitsSurveyCompletedKey);
  if (hasCompletedBenefit) return false;

  const lastSurveyAction = localStorage.getItem(benefitsSurveyKey);
  if (!lastSurveyAction) return true;

  if (lastSurveyAction) {
    const lastSurveyActionDate = new Date(lastSurveyAction);
    const currentTime = new Date().getTime();
    const elapsedTime = currentTime - lastSurveyActionDate.getTime();

    if (elapsedTime > ORDER_NOTIFICATION_DURATION) {
      removeSurveyTimestamp();
    }

    return ORDER_NOTIFICATION_DURATION < elapsedTime;
  }

  return false;
}
