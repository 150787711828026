<template>
  <section>
    <section class="order-confirmation__section">
      <div class="order-confirmation__header">
        <telia-icon name="delete"></telia-icon>
        <telia-heading class="order-confirmation__title" tag="h1" variant="title-100">
          {{ t("REMOVAL_CONFIRMATION.ORDER_TITLE") }}
        </telia-heading>
      </div>
      <div>
        <telia-p v-if="isWithSecureBenefit">
          {{ t("REMOVE_WITH_SECURE.ORDER_TEXT") }}
        </telia-p>
        <telia-p v-else>
          {{
            t("REMOVAL_CONFIRMATION.ORDER_TEXT", {
              benefit: t(`BENEFITS.${getBenefit.name}`),
            })
          }}
        </telia-p>
      </div>
    </section>

    <section class="order-confirmation__section" v-if="isDatasimBenefit">
      <telia-heading class="order-confirmation__section__title" tag="h3" variant="subsection-100">
        {{ t("CONFIRMATION.DATASIM") }}
      </telia-heading>
      <telia-p class="order-confirmation__info">{{ formatPhoneNumber(getBenefit.msisdn) }}</telia-p>
    </section>

    <section class="order-confirmation__section">
      <telia-heading class="order-confirmation__section__title" tag="h3" variant="subsection-100">
        {{ t("CONFIRMATION.ORDER_NUMBER") }}
      </telia-heading>
      <telia-p class="order-confirmation__info"> {{ orderDetails.orderNumber }}</telia-p>
    </section>

    <section class="order-confirmation__section" v-if="!isDataBucketBenefit">
      <telia-p>{{ t("REMOVAL_CONFIRMATION.CONDITION") }}</telia-p>
      <telia-p>
        <telia-link
          variant="text"
          :href="`/foretag/mybusiness/${this.scopeId}/hantera/produkter-tjanster/mobila-abonnemang/${this.selectedSubscription.c2bUid}`"
        >
          {{ t("REMOVAL_CONFIRMATION.MANAGE_LINK") }}
        </telia-link>
      </telia-p>
    </section>
    <div>
      <telia-button
        :text="t('CLOSE')"
        :ally-label="t('CLOSE')"
        variant="secondary"
        size="sm"
        full-width
        type="button"
        @click="closeModal()"
      />
    </div>
  </section>
</template>

<script>
import { translateMixin } from "../../locale";
import { formatPhoneNumber } from "@telia/b2x-phone-number-format";
import { setOrderNotificationTimer } from "../../helper/orderNotificationManager";

export default {
  name: "benefits-remove-confirmation",
  mixins: [translateMixin],
  props: {
    selectedSubscription: {
      type: Object,
      required: true,
    },
    orderDetails: {
      type: Object,
      required: true,
    },
    scopeId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      formatPhoneNumber,
    };
  },
  mounted() {
    setOrderNotificationTimer();
  },
  methods: {
    closeModal() {
      this.$emit("close-modal");
    },
  },
  computed: {
    getBenefit() {
      return this.selectedSubscription.currentHoldings[0];
    },
    isDatasimBenefit() {
      return this.getBenefit.name === "business_plus_datasim";
    },
    isDataBucketBenefit() {
      return this.getBenefit.name === "business_plus_databucket";
    },
    isWithSecureBenefit() {
      return this.getBenefit.name === "business_plus_withsecure";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "node_modules/@teliads/components/foundations/spacing/variables";
.order-confirmation {
  &__header {
    display: flex;
    align-items: center;
    telia-icon {
      margin-bottom: $telia-spacing-8;
      margin-right: $telia-spacing-8;
    }
  }
  &__section {
    &__title {
      margin-bottom: 0;
    }
    margin-bottom: $telia-spacing-24;
  }
  &__title {
    margin-bottom: $telia-spacing-8;
  }
  &__button {
    margin-top: $telia-spacing-16;
  }
  &__alert {
    margin-top: $telia-spacing-16;
  }
  &__list {
    list-style: none;
  }
  &__info {
    margin-bottom: $telia-spacing-16;
  }
}
</style>
