const ORDER_NOTIFICATION_DURATION = 12 * 60 * 60 * 1000;
const orderNotificationKey = "benefits-order-notification-timer";

export const setOrderNotificationTimer = () => {
  const orderDate = new Date();
  localStorage.setItem(orderNotificationKey, orderDate.toString());
};

export const removeOrderNotificationTimer = () => {
  localStorage.removeItem(orderNotificationKey);
};

export function shouldShowOrderNotification() {
  const lastOrderTime = localStorage.getItem(orderNotificationKey);

  if (lastOrderTime) {
    const lastOrderDate = new Date(lastOrderTime);
    const currentTime = new Date().getTime();
    const elapsedTime = currentTime - lastOrderDate.getTime();

    if (elapsedTime > ORDER_NOTIFICATION_DURATION) {
      removeOrderNotificationTimer();
    }

    return ORDER_NOTIFICATION_DURATION > elapsedTime;
  }

  return false;
}
