<template>
  <div data-test-id="subscription-overview" class="subscription-overview">
    <b2x-modal :is-open="showBenefitsSurvey" hide-close-button>
      <span slot="modal-body">
        <div id="business_plus_benefits_survey"></div>
      </span>
    </b2x-modal>

    <div class="benefit-drawer">
      <b2x-drawer
        v-if="selectedTscid"
        drawer-id="benefit-drawer-add"
        position="right"
        :heading="t('ADD_BENEFIT')"
        :is-open="isAddDrawerOpen"
        @drawerClose="hideAddModal()"
      >
        <benefits-add-configuration
          :selected-subscription="selectedSubscription"
          :scope-id="scopeId"
          :selected-tscid="selectedTscid"
          :is-telia-admin="isTeliaAdmin"
          :render-component="isAddDrawerOpen"
          @close-modal="hideAddModal()"
          @set-ongoing="setOngoing($event)"
          @is-submitting="isSubmitting = $event"
        />
      </b2x-drawer>
      <b2x-drawer
        v-if="selectedTscid"
        drawer-id="benefit-drawer-remove"
        position="right"
        :heading="t('MANAGE_BENEFIT')"
        :is-open="isRemoveDrawerOpen"
        @drawerClose="hideRemoveModal()"
      >
        <benefits-remove-configuration
          :selected-subscription="selectedSubscription"
          :scope-id="scopeId"
          :selected-tscid="selectedTscid"
          :is-telia-admin="isTeliaAdmin"
          :render-component="isRemoveDrawerOpen"
          :in-removal-confirmation="inRemovalConfirmation"
          @close-modal="hideRemoveModal()"
          @is-submitting="isSubmitting = $event"
          @set-ongoing="setOngoing($event)"
          @set-removal-confirmation="setRemovalConfirmation"
        />
      </b2x-drawer>
    </div>

    <section v-if="state === STATES.FETCH_ERROR" class="notification-wrapper">
      <notification
        t-id="error-notification"
        status="warning"
        :heading="t('NOTIFICATION.add.error.heading')"
        :message="t('ERRORS.SUBSCRIPTION')"
        :a11y-message="t('NOTIFICATION.a11yStatus.warning')"
      />
    </section>

    <section v-if="state === STATES.IDLE && !isValidToOrder" class="notification-wrapper">
      <telia-notification status="information" heading-tag="h4">
        <span slot="heading">
          <telia-visually-hidden>
            {{ t("NOTIFICATION.a11yStatus.information") }}
          </telia-visually-hidden>
          <strong>{{ t("NOTIFICATION.not_valid_to_order.information.heading") }}</strong>
        </span>
        <span slot="content">
          <telia-p>
            {{ t("NOTIFICATION.not_valid_to_order.information.message") }}
            <telia-link href="https://www.telia.se/foretag/teliapluskund" target="_blank">
              {{ t("NOTIFICATION.not_valid_to_order.information.message_link_text") }}
            </telia-link>
          </telia-p>
        </span>
      </telia-notification>
    </section>

    <section v-if="state === STATES.LOADING || (state === STATES.IDLE && isValidToOrder)">
      <section v-if="isValidToOrder" class="benefits-information-container">
        <telia-button
          v-if="amountOfSelectedBenefits > 0"
          variant="text"
          @click="handleShowBenefitsSurvey"
        >
          {{ t("SURVEY.LINK") }}
        </telia-button>
        <section
          v-if="showOrderNotification || showBenefitsTableNotification"
          class="benefits-information-notification"
        >
          <telia-notification
            status="information"
            :button-text="t('NOTIFICATION.close')"
            @vocaClose="closeNotification"
            heading-tag="h4"
          >
            <span slot="heading">
              <telia-visually-hidden>
                {{ t("NOTIFICATION.a11yStatus.information") }}
              </telia-visually-hidden>
              <strong>{{ t("NOTIFICATION.information.benefits_table.heading") }}</strong>
            </span>
            <span slot="content">
              <telia-p>
                {{ t("NOTIFICATION.information.benefits_table.message_pt1") }}
                <telia-button
                  type="button"
                  size="sm"
                  variant="text"
                  :text="t('NOTIFICATION.information.benefits_table.update')"
                  @click="getBenefits"
                />
                {{ t("NOTIFICATION.information.benefits_table.message_pt2") }}
              </telia-p>
            </span>
          </telia-notification>
        </section>
        <div v-if="isMobile" class="description-wrapper-mobile">
          <telia-p class="subscription-description__mobile" variant="paragraph-100">
            {{ t("SUBSCRIPTION_DESCRIPTION") }}
          </telia-p>
          <telia-divider />
        </div>
      </section>
      <subscription-table-mobile
        v-if="isMobile"
        :subscriptions="subscriptionRowsMobile"
        @addBenefitClicked="handleAddBenefit"
      />
      <subscription-table
        v-else
        t-id="user-table"
        :loading="state"
        :subscriptions="subscriptionRows"
        @addBenefitClicked="handleAddBenefit"
      />
    </section>
  </div>
</template>

<script>
import { corpGoodiesService } from "@telia/b2b-rest-client";
import { formatPhoneNumber } from "@telia/b2x-phone-number-format";
import { eventAction, trackBenefitsEvent, trackSurveyClick } from "../helper/benefitTracker";
import {
  trackInitiate,
  trackFailedOrder,
  trackSurveyInitiate,
  trackBenefitRemoveInitiate,
} from "../helper/analyticsManager";
import {
  setSurveyBlockTimer,
  shouldShowSurvey,
  setSurveyCompletedBlock,
} from "../helper/medalliaTimeManager";
import {
  shouldShowOrderNotification,
  removeOrderNotificationTimer,
} from "../helper/orderNotificationManager";
import { translateMixin } from "../locale";
import benefitsAddConfiguration from "./benefit-configuration/benefits-add-configuration.vue";
import benefitsRemoveConfiguration from "./benefit-configuration/benefits-remove-configuration.vue";
import subscriptionTable from "./subscription-table.vue";
import subscriptionTableMobile from "./subscription-table-mobile.vue";
import notification from "./notification.vue";

const MAXIMUM_ALLOWED_BENEFITS = 10;
const STATES = Object.freeze({
  IDLE: "idle",
  LOADING: "loading",
  FETCH_ERROR: "fetch_error",
});
const ORDER_STATUS = Object.freeze({
  VALID_TO_ORDER: "VALID_TO_ORDER",
  VALID_TO_CHANGE: "VALID_TO_CHANGE",
  NOT_VALID_TO_ORDER: "NOT_VALID_TO_ORDER",
  NOT_VALID_TO_CHANGE: "NOT_VALID_TO_CHANGE",
});

export default {
  name: "subscription-overview",
  mixins: [translateMixin],

  components: {
    benefitsAddConfiguration,
    benefitsRemoveConfiguration,
    subscriptionTable,
    subscriptionTableMobile,
    notification,
  },

  props: {
    scopeId: {
      type: String,
      required: true,
    },
    selectedTscid: {
      type: String,
      required: true,
    },
    isTeliaAdmin: {
      type: Boolean,
      default: false,
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      STATES,
      isValidToOrder: false,
      eligibleSubscriptions: [],
      selectedSubscription: {},
      isAddDrawerOpen: false,
      isRemoveDrawerOpen: false,
      isSubmitting: false,
      inRemovalConfirmation: false,
      state: STATES.LOADING,
      showBenefitsTableNotification: false,
      showOrderNotification: false,
      showBenefitsSurvey: false,
      benefitAdded: false,
    };
  },

  async mounted() {
    await this.getBenefits();
    this.showOrderNotification = shouldShowOrderNotification();
    window.addEventListener("MDigital_Form_Close_No_Submit", () => {
      this.handleCloseBenefitsSurvey();
      setSurveyBlockTimer();
    });
    window.addEventListener("MDigital_ThankYou_Close", () => {
      this.handleCloseBenefitsSurvey();
      setSurveyCompletedBlock();
    });
  },

  computed: {
    subscriptionRows() {
      return this.eligibleSubscriptions.map((s) => ({
        subscriptionId: s.c2bUid,
        msisdn: `${formatPhoneNumber(s.msisdn)}${s.name ? " / " + s.name : ""}`,
        offeringName: s.offeringName,
        externalReference: s.orderOnAssetSelection[0]?.externalReference,
        currentHoldings: s.currentHoldings.length
          ? {
              description: this.t(`BENEFITS.${s.currentHoldings[0].name}`),
              name: s.currentHoldings[0].name,
              ongoingOrder: s.currentHoldings[0].ongoingOrder,
            }
          : null,
      }));
    },
    subscriptionRowsMobile() {
      return this.eligibleSubscriptions.map((s) => ({
        subscriptionId: s.c2bUid,
        msisdn: `${formatPhoneNumber(s.msisdn)}`,
        offeringName: s.offeringName,
        name: s.name,
        externalReference: s.orderOnAssetSelection[0]?.externalReference,
        currentHoldings: s.currentHoldings.length
          ? {
              description: this.t(`BENEFITS.${s.currentHoldings[0].name}`),
              name: s.currentHoldings[0].name,
              ongoingOrder: s.currentHoldings[0].ongoingOrder,
            }
          : null,
      }));
    },
    amountOfSelectedBenefits() {
      return this.eligibleSubscriptions.filter((s) => s.currentHoldings.length).length;
    },
    amountOfBenefitsRemaining() {
      return this.amountOfBenefitsAllowed - this.amountOfSelectedBenefits;
    },

    amountOfBenefitsAllowed() {
      return this.eligibleSubscriptions.length > MAXIMUM_ALLOWED_BENEFITS - 1
        ? MAXIMUM_ALLOWED_BENEFITS
        : this.eligibleSubscriptions.length;
    },
  },
  watch: {
    amountOfBenefitsRemaining(newValue, oldValue) {
      if (newValue === oldValue) return;
      this.$emit("amountOfBenefitsRemaining", newValue);
    },
    amountOfBenefitsAllowed(newValue, oldValue) {
      if (newValue === oldValue) return;
      this.$emit("amountOfBenefitsAllowed", newValue);
    },
  },

  methods: {
    async getBenefits() {
      try {
        this.state = STATES.LOADING;
        const resp = await corpGoodiesService.GoodiesControllerService.listGoodiesPerSubscriptionUsingGet(
          this.scopeId,
          this.selectedTscid
        );
        this.isValidToOrder =
          resp.status === ORDER_STATUS.VALID_TO_ORDER ||
          resp.status === ORDER_STATUS.VALID_TO_CHANGE ||
          resp.status === ORDER_STATUS.NOT_VALID_TO_CHANGE;
        this.eligibleSubscriptions = resp.eligible ?? [];
        this.$emit("isValidToOrder", this.isValidToOrder);
        this.state = STATES.IDLE;
      } catch (error) {
        this.state = STATES.FETCH_ERROR;
        trackFailedOrder("benefit_manage_tab");
      }
    },

    handleAddBenefit(subscriptionId) {
      const selectedSubscription = this.eligibleSubscriptions.find(
        (subscription) => subscription.c2bUid === subscriptionId
      );

      selectedSubscription.currentHoldings.length
        ? this.removeBenefit(selectedSubscription)
        : this.addBenefit(selectedSubscription);
    },

    addBenefit(subscription) {
      this.selectedSubscription = subscription;
      this.isAddDrawerOpen = true;
      trackBenefitsEvent(eventAction.CLICK, "Open add benefit");
      trackInitiate();
    },

    setOngoing(event) {
      this.eligibleSubscriptions = this.eligibleSubscriptions.map((s) => {
        return s.c2bUid === event.id ? { ...s, currentHoldings: [event.currentHolding] } : { ...s };
      });
      this.showBenefitsTableNotification = true;
      this.benefitAdded = true;
    },

    removeBenefit(subscription) {
      this.selectedSubscription = subscription;
      this.isRemoveDrawerOpen = true;
      trackBenefitsEvent(eventAction.CLICK, "Open manage benefit");
      trackBenefitRemoveInitiate(subscription?.currentHoldings[0]?.name);
    },

    hideAddModal() {
      if (this.isSubmitting) return;
      this.isAddDrawerOpen = false;
      this.selectedSubscription = {};

      if (shouldShowSurvey() && this.benefitAdded) {
        this.handleShowBenefitsSurvey();
      }
    },

    hideRemoveModal() {
      if (this.isSubmitting) return;
      this.inRemovalConfirmation = false;
      this.isRemoveDrawerOpen = false;
      this.selectedSubscription = {};
    },

    setRemovalConfirmation(removalConfirmation) {
      this.inRemovalConfirmation = removalConfirmation;
    },
    closeNotification() {
      this.showBenefitsTableNotification = false;
      this.showOrderNotification = false;
      removeOrderNotificationTimer();
    },
    handleShowBenefitsSurvey() {
      this.showBenefitsSurvey = true;
      trackSurveyClick();
      trackSurveyInitiate("click", "What do you think about the Telia Business Plus offering?");
      window.KAMPYLE_ONSITE_SDK.updatePageView();
    },
    handleCloseBenefitsSurvey() {
      this.showBenefitsSurvey = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/colors/variables";
@import "~@teliads/components/foundations/spacing/variables";

.subscription-overview {
}
.subscription-description {
  padding: 0 0 $telia-spacing-24;
  &__mobile {
    padding: 0 0 $telia-spacing-12;
  }
}
.description-wrapper-mobile {
  margin-bottom: $telia-spacing-12;
}

.benefits-information-container {
  @media screen and (max-width: 500px) {
    margin-top: 0;
  }
}

.benefits-information-notification {
  margin-bottom: $telia-spacing-24;
  margin-top: $telia-spacing-12;
}

.benefits-information {
  padding: 0 0 $telia-spacing-12;
}

.notification-wrapper {
  margin-bottom: $telia-spacing-24;
}

.purple {
  color: $telia-purple-500;
}
</style>
