<template>
  <telia-grid>
    <telia-row spacing="md">
      <telia-col width="12">
        <telia-p>
          <strong>{{ t("SUBSCRIPTION") }}</strong>
        </telia-p>
        <telia-p v-if="selectedSubscription.name">{{ selectedSubscription.name }}</telia-p>
        <telia-p>{{ formatPhoneNumber(selectedSubscription.msisdn) }}</telia-p>
      </telia-col>
    </telia-row>

    <telia-row spacing="md">
      <telia-col :width="getBenefit ? '5' : '9'">
        <telia-p>
          <strong>{{ t("SELECTED_BENEFIT") }}</strong>
        </telia-p>
        <telia-p>{{ getBenefitName }}</telia-p>
      </telia-col>
      <telia-col width="3">
        <telia-p>
          <strong>{{ t("STATUS") }}</strong>
        </telia-p>
        <telia-p>{{ getBenefitStatus }}</telia-p>
      </telia-col>
      <telia-col v-if="getBenefit" width="4">
        <telia-p>
          <strong>{{ t("ACTIVATION_DATE") }}</strong>
        </telia-p>
        <telia-p>{{ getActivatedDate }}</telia-p>
      </telia-col>
    </telia-row>
  </telia-grid>
</template>

<script>
import { translateMixin } from "../../locale";
import { formatPhoneNumber } from "@telia/b2x-phone-number-format";

export default {
  name: "benefits-info",
  mixins: [translateMixin],
  props: {
    selectedSubscription: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      formatPhoneNumber,
    };
  },
  computed: {
    getBenefit() {
      return this.selectedSubscription.currentHoldings[0];
    },
    getBenefitName() {
      return this.t(`BENEFITS.${this.getBenefit?.name}`) ?? this.t("NO_BENEFIT");
    },
    getBenefitStatus() {
      const benefit = this.getBenefit;
      if (!benefit) return this.t("INACTIVE");
      return benefit.ongoingOrder ? this.t("ONGOING") : this.t("ACTIVE");
    },
    getActivatedDate() {
      return this.getBenefit?.ordered ?? this.t("NOT_ACTIVE");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables";

telia-grid {
  padding: 0;
  margin-bottom: $telia-spacing-24;
}
</style>
