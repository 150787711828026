<template>
  <section>
    <b2x-controlled-table
      :is-loading="loading === STATES.LOADING"
      ref="table"
      :columns="JSON.stringify(columns)"
      :data="JSON.stringify(subscriptionRowsForPage)"
      :table-rows-count="subscriptionRows.length"
      :table-rows-limit="subscriptionRowsForPage.length"
      :message="noSubscriptionMessage"
      :column-filter-label="t('mybusiness.columnManager')"
      :reset-column-label="t('mybusiness.resetColumns')"
      show-filter-row
      show-clear-filters-button
      :clear-filters-button-disabled="isClearFiltersButtonDisabled"
      @buttonTextClicked="handleButtonClick"
      @filterColumnInput="onFilterColumnInput"
      @clearAllFilters="onClearAllFilters"
      @columnClick="onColumnClick"
    ></b2x-controlled-table>
    <b2x-paginator
      v-show="loading !== STATES.LOADING"
      :list-length="subscriptionRows.length"
      :page-sizes="JSON.stringify(pageSizes)"
      :default-page-size="defaultPageSize"
      @paginationChange="handlePageChange"
    />
  </section>
</template>

<script>
import { translateMixin } from "../locale";
import { eventAction, trackBenefitsEvent, trackColumnSearch } from "../helper/benefitTracker";

import {
  createTableColumn,
  makeRowsFromSubscriptions,
  getColumnTitle,
} from "../helper/benefitsTableManager";

const STATES = Object.freeze({
  IDLE: "idle",
  LOADING: "loading",
  FETCH_ERROR: "fetch_error",
});

export default {
  name: "subscription-table",
  mixins: [translateMixin],
  props: {
    subscriptions: {
      type: Array,
      required: true,
    },
    loading: {
      type: String,
      required: true,
    },
  },
  data: () => {
    return {
      STATES,
      columnFilter: {},
      selectedSorting: "NAME_MSISDN",
      sortDirection: "DSC",
      defaultPageSize: 10,
      pageSizes: [10, 25, 50],
      page: 1,
      pageSize: 10,
    };
  },

  computed: {
    columns() {
      return createTableColumn({
        column: this.selectedSorting,
        sortDirection: this.sortDirection,
      });
    },
    rows() {
      let filteredRows = this.searchRows();
      return this.selectedSorting ? this.sortRows(filteredRows) : filteredRows;
    },
    subscriptionRows() {
      return makeRowsFromSubscriptions(this.rows);
    },
    subscriptionRowsForPage() {
      return this.subscriptionRows.slice(
        (this.page - 1) * this.pageSize,
        this.page * this.pageSize
      );
    },
    noSubscriptionMessage() {
      return !this.rows?.length ? this.t("NO_SUBSCRIPTION") : "";
    },
    tableRowsLimit() {
      return this.pageSize > this.subscriptionRowsForPage.length
        ? this.subscriptionRowsForPage.length
        : this.pageSize;
    },
    isClearFiltersButtonDisabled() {
      return Object.keys(this.columnFilter).length === 0;
    },
  },

  methods: {
    onClearAllFilters() {
      this.columnFilter = {};
      trackBenefitsEvent(eventAction.CLEAR, "Benefit filters");
    },
    searchRows() {
      let filteredRows = [...this.subscriptions];
      Object.keys(this.columnFilter).forEach((columnKey) => {
        const search = this.columnFilter[columnKey];
        switch (columnKey) {
          case "NAME_MSISDN":
            filteredRows = filteredRows.filter(
              (row) =>
                /* Support number search both with and without whitespace */
                row.msisdn.toLowerCase().includes(search) ||
                row.msisdn.replace(/\s/g, "").toLowerCase().includes(search)
            );
            break;
          case "PRODUCT":
            filteredRows = filteredRows.filter((row) =>
              row.offeringName?.toLowerCase().includes(search)
            );
            break;
          case "SUBSCRIPTION_REFERENCE":
            filteredRows = filteredRows.filter((row) =>
              row.externalReference?.toLowerCase().includes(search)
            );
            break;
          case "CURRENT_HOLDING":
            filteredRows = filteredRows.filter((row) =>
              row.currentHoldings?.description?.toLowerCase().includes(search)
            );
            break;
          case "STATUS":
            //TODO temporary just for search to return something
            if (search.startsWith(this.t("ACTIVE").toLowerCase())) {
              filteredRows = filteredRows.filter((row) => row.currentHoldings);
            } else if (search.startsWith(this.t("INACTIVE").toLowerCase())) {
              filteredRows = filteredRows.filter((row) => !row.currentHoldings);
            } else if (search.startsWith(this.t("ONGOING").toLowerCase())) {
              filteredRows = filteredRows.filter(
                (row) => row.currentHoldings && row.currentHoldings.ongoingOrder
              );
            }
            break;
          default:
            break;
        }
      });
      return filteredRows;
    },
    sortRows(filteredRows) {
      if (!this.selectedSorting) {
        return filteredRows;
      }

      switch (this.selectedSorting) {
        case "NAME_MSISDN":
          filteredRows.sort((a, b) =>
            a.msisdn.localeCompare(b.msisdn, undefined, { numeric: true, sensitivity: "base" })
          );
          break;
        case "PRODUCT":
          filteredRows.sort((a, b) => a.offeringName?.localeCompare(b.offeringName));
          break;
        case "SUBSCRIPTION_REFERENCE":
          filteredRows.sort((a, b) => a.externalReference?.localeCompare(b.externalReference));
          break;
        case "CURRENT_HOLDING":
          filteredRows.sort((a, b) =>
            a.currentHoldings?.description.localeCompare(b.currentHoldings?.description)
          );
          break;
        case "STATUS":
          filteredRows.sort((a, b) => !!b.currentHoldings - !!a.currentHoldings);
          break;
        default:
          break;
      }

      if (this.sortDirection === "DSC") {
        filteredRows.reverse();
      }

      return filteredRows;
    },
    onFilterColumnInput(event) {
      const { column, value } = event.detail;
      const titleKey = getColumnTitle(column);

      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        if (value?.length > 0) {
          this.columnFilter = {
            ...this.columnFilter,
            [titleKey]: value.trim().toLowerCase(),
          };
        } else {
          const columnFilter = { ...this.columnFilter };
          delete columnFilter[titleKey];
          this.columnFilter = columnFilter;
        }
      });
      trackColumnSearch(titleKey);
    },
    onColumnClick(event) {
      const titleKey = getColumnTitle(event.detail);
      this.setSort(titleKey);
    },
    setSort(selectedSorting) {
      if (this.selectedSorting === selectedSorting) {
        this.sortDirection = this.sortDirection === "ASC" ? "DSC" : "ASC";
      } else {
        this.sortDirection = "DSC";
      }
      this.selectedSorting = selectedSorting;
    },
    handleButtonClick(event) {
      const subscription = this.rows[event.detail.rowNumber];
      this.$emit("addBenefitClicked", subscription.subscriptionId);
    },
    handlePageChange(event) {
      const { page, pageSize } = event.detail;
      this.page = page;
      this.pageSize = pageSize;
    },
  },
};
</script>
