<template>
  <div class="mobile-list">
    <div class="search-wrapper">
      <telia-text-input
        type="text"
        @input="inputValue = $event.target.value"
        :placeholder="t('MOBILE.SEARCH')"
      />
      <div class="icon-wrapper"><telia-icon name="search" size="md" /></div>
    </div>
    <div v-for="subscription in subscriptionList" :key="subscription.subscriptionId">
      <div class="list-item-wrapper">
        <div class="list-button">
          <telia-button
            variant="text"
            size="sm"
            :disabled="disableRemoveButton(subscription)"
            @click="handleMobileButtonClick(subscription)"
          >
            {{ !subscription.currentHoldings ? t("ADD") : t("REMOVE") }}
          </telia-button>
        </div>
        <div class="list-item-info" @click="toggleAccordion(subscription.subscriptionId)">
          <div>
            <telia-p variant="additional-100">{{ subscription.name }} </telia-p>
            <telia-p variant="additional-100">{{ subscription.msisdn }} </telia-p>
          </div>
        </div>
        <div
          @click="toggleAccordion(subscription.subscriptionId)"
          :class="`${
            expandedItems.includes(subscription.subscriptionId) ? 'chevron' : 'chevron__open'
          }`"
        >
          <telia-icon name="chevron-up" size="sm" />
        </div>
      </div>
      <div
        class="dropdown"
        :class="{ expanded: expandedItems.includes(subscription.subscriptionId) }"
        @click="toggleAccordion(subscription.subscriptionId)"
      >
        <telia-p>{{ t("COLUMNS.PRODUCT") }}</telia-p>
        <telia-p variant="additional-100">{{ subscription.offeringName }}</telia-p>
        <br />
        <telia-p>{{ t("COLUMNS.CURRENT_HOLDING") }}</telia-p>
        <telia-p variant="additional-100">
          {{
            subscription.currentHoldings
              ? subscription.currentHoldings.description
              : t("NO_BENEFIT")
          }}
        </telia-p>
      </div>
    </div>

    <div class="show-more-wrapper">
      <telia-p variant="additional-100">
        {{
          t("MOBILE.SHOWING_SUBSCRIPTIONS", {
            amount: subscriptionList.length,
            total: subscriptions.length,
          })
        }}
      </telia-p>
      <telia-button v-if="hasHiddenSubscriptions" variant="text" size="sm" @click="showMore">
        {{ t("MOBILE.SHOW_MORE") }}
      </telia-button>
    </div>
  </div>
</template>

<script>
import { translateMixin } from "../locale";

export default {
  name: "SubscriptionTableMobile",
  mixins: [translateMixin],
  props: {
    subscriptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      expandedItems: [],
      inputValue: "",
      SubscriptionsDisplayed: 8,
    };
  },
  computed: {
    subscriptionList() {
      return this.sortedSubscriptions.slice(0, this.SubscriptionsDisplayed);
    },
    filteredSubscriptions() {
      const inputValue = this.inputValue.toLowerCase().replace(/[-\s]/g, "");
      if (inputValue === "") {
        return this.subscriptions;
      } else {
        return this.subscriptions.filter((subscription) => {
          const subscriptionName = subscription?.name?.toLowerCase().replace(/[-\s]/g, "");
          const subscriptionPhoneNumber = subscription?.msisdn?.replace(/[-\s]/g, "");
          return (
            subscriptionName?.includes(inputValue) || subscriptionPhoneNumber?.includes(inputValue)
          );
        });
      }
    },
    sortedSubscriptions() {
      return this.filteredSubscriptions.slice().sort((a, b) => {
        if (a.currentHoldings !== null && b.currentHoldings === null) {
          return -1;
        } else if (a.currentHoldings === null && b.currentHoldings !== null) {
          return 1;
        } else {
          return 0;
        }
      });
    },
    hasHiddenSubscriptions() {
      return this.sortedSubscriptions.length > this.SubscriptionsDisplayed;
    },
  },
  methods: {
    handleMobileButtonClick(subscription) {
      this.$emit("addBenefitClicked", subscription.subscriptionId);
    },
    toggleAccordion(subscriptionId) {
      if (this.expandedItems.includes(subscriptionId)) {
        this.expandedItems = this.expandedItems.filter((id) => id !== subscriptionId);
      } else {
        this.expandedItems.push(subscriptionId);
      }
    },
    showMore() {
      this.SubscriptionsDisplayed += 8;
    },
    disableRemoveButton(subscription) {
      return subscription.currentHoldings?.ongoingOrder;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables";
@import "~@teliads/components/foundations/colors/variables";
.mobile-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.list-item-wrapper {
  background-color: $telia-gray-50;
  display: flex;
  padding: 0.5rem 1rem 0.5rem 0.5rem;
  align-items: center;
  justify-content: space-between;
  z-index: 99;
}
.list-item-info {
  flex: 1;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  > * {
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    padding-right: 2rem;
  }
}
.dropdown {
  height: 0rem;
  padding: 0 1rem;
  transition: height 0.3s ease-in-out;
  background-color: $telia-gray-50;
  pointer-events: none;
  overflow: hidden;
}
.dropdown.expanded {
  height: 11rem;
  background-color: $telia-gray-50;

  pointer-events: all;
}
.search-wrapper {
  position: relative;
  padding-bottom: $telia-spacing-8;
}
.chevron {
  transition: transform 0.3s ease;
  &__open {
    transform: rotateX(180deg);
  }
}
.show-more-wrapper {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: $telia-spacing-8;
}
.list-button {
  padding: 0 1rem;
}
.icon-wrapper {
  position: absolute;
  right: 1rem;
  top: 1.5rem;
  color: $telia-gray-300;
}
</style>
