<template>
  <section>
    <div v-if="!isPrerequisitesFulfilled" class="notification-container">
      <notification
        :status="'warning'"
        :heading="t('NOTIFICATION.eligibility.error.heading')"
        :message="t('NOTIFICATION.eligibility.error.message')"
        :a11y-message="t('NOTIFICATION.a11yStatus.warning')"
      />
    </div>

    <div v-else-if="!contentData" class="container">
      <telia-skeleton class="skeleton-badge" />
      <telia-skeleton class="skeleton-header" />
      <telia-skeleton class="skeleton-body" />

      <div class="button-wrapper">
        <telia-skeleton round class="skeleton-button" />
        <telia-skeleton class="skeleton-button" />
      </div>
    </div>

    <div v-else class="container">
      <div class="badge-wrapper">
        <telia-badge variant="information">{{ t("START.NEW") }}</telia-badge>
      </div>

      <div class="header-wrapper">
        <telia-icon name="present"></telia-icon>
        <telia-heading tag="h1" variant="title-100">
          {{ contentData.title }}
        </telia-heading>
      </div>

      <div class="info-wrapper">
        <b2x-rich-text v-if="contentData.richPreamble" :json="contentData.richPreamble" />
      </div>

      <div class="button-wrapper">
        <!-- TODO: HIDE BUTTON UNTIL VOCA FIXES THE TELIA-TAB STATE BUG
        <telia-button
          v-if="isOrganizationEligible"
          :text="t('START.MANAGE_BENEFITS')"
          variant="primary"
          @click="switchTab"
        /> -->
        <telia-link
          href="https://www.telia.se/foretag/teliapluskund"
          target="_blank"
          :link-title="t('START.READ_MORE_LINK_TITLE')"
          @click="analyticsOnClick"
        >
          <telia-p>
            {{ t("START.READ_MORE") }}
            <telia-icon name="external" size="sm" />
          </telia-p>
        </telia-link>
      </div>
    </div>
  </section>
</template>

<script>
import { translateMixin } from "../locale";
import { client, BENEFIT_INFORMATION, BENEFIT_NOT_ELIGIBLE_INFORMATION } from "../../api";
import { eventAction, trackBenefitsEvent } from "../helper/benefitTracker";
import notification from "../components/notification.vue";

export default {
  name: "subscription-start-page",
  mixins: [translateMixin],
  components: {
    notification,
  },
  props: {
    scopeId: {
      type: String,
      required: true,
    },
    isPrerequisitesFulfilled: {
      type: Boolean,
      default: false,
    },
    isOrganizationEligible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      contentData: null,
    };
  },
  async mounted() {
    await this.setupContentData();
  },
  methods: {
    switchTab() {
      this.$emit("switch-tab", { detail: { value: "benefits" } });
    },
    async setupContentData() {
      if (this.isOrganizationEligible) {
        const data = await client.request(BENEFIT_INFORMATION);
        this.contentData = data?.teaserWrapper?.teaserList[1];
      } else {
        const data = await client.request(BENEFIT_NOT_ELIGIBLE_INFORMATION);
        this.contentData = data?.teaserWrapper?.teaserList[0];
      }
    },
    analyticsOnClick() {
      trackBenefitsEvent(eventAction.CLICK, "To open pages");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@teliads/components/foundations/borders/variables";
@import "~@teliads/components/foundations/shadows/variables";
@import "~@teliads/components/foundations/spacing/variables";

.button-wrapper {
  margin-top: $telia-spacing-24;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.container {
  padding: $telia-spacing-24;
  border: $telia-border-width-1 solid;
  border-radius: $telia-border-radius-8;
  box-shadow: $telia-shadow-md;
  width: 50%;
  @media screen and (max-width: 500px) {
    width: 100%;
  }
  div:not(:last-of-type) {
    margin-bottom: $telia-spacing-24;
  }

  .header-wrapper {
    display: flex;
    align-items: center;

    telia-icon {
      margin-right: $telia-spacing-8;
    }
  }
}

.notification-container {
  max-width: 50rem;
}

telia-skeleton {
  margin-bottom: $telia-spacing-24;
  &.skeleton-badge {
    width: 5rem;
    height: 2rem;
  }
  &.skeleton-header {
    width: 20rem;
    height: 3rem;
  }
  &.skeleton-body {
    width: 60rem;
    height: 12rem;
  }
  &.skeleton-button {
    width: 20rem;
    height: 4rem;
  }
}
</style>
