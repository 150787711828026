<template>
  <div v-if="renderComponent">
    <section v-if="status === 'success'" class="notification-wrapper">
      <benefits-remove-confirmation
        :selectedSubscription="selectedSubscription"
        :order-details="removalDetails"
        :scope-id="scopeId"
        @close-modal="closeModal()"
      />
    </section>

    <div v-else>
      <div t-id="remove-configuration" v-if="!inRemovalConfirmation">
        <benefits-info :selected-subscription="selectedSubscription" />
        <section class="valid-remove-date-wrapper" v-if="!benefitCanBeRemoved">
          <notification
            status="information"
            :heading="
              t('BENEFIT_CAN_EARLIEST_BE_REMOVED', {
                date: getBenefit.validChangeDate,
              })
            "
          />
        </section>

        <section class="info-wrapper" v-if="isDatasimBenefit">
          <telia-heading tag="h3" variant="subsection-100">
            {{ t("REMOVE_DATASIM.DATASIM", { datasim: formatPhoneNumber(getBenefit.msisdn) }) }}
            {{ t("REMOVE_DATASIM.KEEP_SERVICE") }}
          </telia-heading>
          <div class="remove-datasim-service">
            <div class="radio-button-wrapper">
              <telia-radio-button
                :label="t('REMOVE_DATASIM.KEEP_SERVICE_LABEL')"
                @change="shouldRemoveDatasimService = false"
                :checked="shouldRemoveDatasimService === false"
              />
              <telia-p variant="additional-100">
                {{ t("REMOVE_DATASIM.KEEP_SERVICE_DESCRIPTION") }}
              </telia-p>
            </div>
            <div class="radio-button-wrapper">
              <telia-radio-button
                :label="t('REMOVE_DATASIM.REMOVE_SERVICE_LABEL')"
                @change="shouldRemoveDatasimService = true"
                :checked="shouldRemoveDatasimService === true"
              />
              <telia-p variant="additional-100">
                {{ t("REMOVE_DATASIM.REMOVE_SERVICE_DESCRIPTION") }}
              </telia-p>
            </div>
          </div>

          <div class="datasim-email-wrapper" v-if="shouldRemoveDatasimService">
            <telia-text-input
              :value="datasimEmailSelection.value"
              @input="emailInput"
              pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$"
              name="email"
              type="email"
              maxlength="50"
              :label="t('REMOVE_DATASIM.ENTER_EMAIL')"
              :required-error-message="t('ERRORS.EMAIL')"
              :email-error-message="t('ERRORS.EMAIL_INVALID')"
              required
            />
          </div>
        </section>

        <section>
          <div class="button-wrapper">
            <div>
              <telia-button
                :text="t('REMOVE')"
                variant="destructive"
                size="sm"
                full-width
                :disabled="
                  disableOnSubmitAndSuccess ||
                  isTeliaAdmin ||
                  !benefitCanBeRemoved ||
                  !canRemoveDatasimBenefit
                "
                @click="showRemoveConfirmation"
              />
            </div>
            <div>
              <telia-button
                :text="t('CLOSE')"
                variant="secondary"
                size="sm"
                full-width
                :disabled="isSubmitting"
                @click="closeModal"
              />
            </div>
          </div>
        </section>
      </div>

      <section t-id="remove-confirmation" v-else>
        <div class="remove-header">
          <telia-heading tag="h3" v-if="isDatasimBenefit">
            {{
              shouldRemoveDatasimService
                ? t("REMOVE_DATASIM.TITLE_SERVICE")
                : t("REMOVE_DATASIM.TITLE")
            }}
          </telia-heading>
          <telia-heading tag="h3" v-else>{{ t("REMOVE_BENEFIT.title") }}</telia-heading>
          <section class="info-wrapper">
            <telia-p v-if="isDatasimBenefit">
              {{
                shouldRemoveDatasimService
                  ? t("REMOVE_DATASIM.DATASIM_AND_SERVICE", {
                      datasim: formatPhoneNumber(getBenefit.msisdn),
                    })
                  : t("REMOVE_DATASIM.DATASIM", { datasim: formatPhoneNumber(getBenefit.msisdn) })
              }}
            </telia-p>
            <telia-p v-else-if="isWithSecureBenefit">
              {{ t("REMOVE_WITH_SECURE.DESCRIPTION") }}
            </telia-p>
            <telia-p v-else>
              {{ t("REMOVE_BENEFIT.description", { benefit: t(`BENEFITS.${getBenefit.name}`) }) }}
            </telia-p>
          </section>
        </div>

        <div class="button-wrapper">
          <div>
            <telia-button
              :text="t('CONFIRM_REMOVE')"
              variant="destructive"
              size="sm"
              full-width
              :disabled="
                disableOnSubmitAndSuccess ||
                isTeliaAdmin ||
                !benefitCanBeRemoved ||
                !canRemoveDatasimBenefit
              "
              @click="handleBenefit"
            />
          </div>
          <div>
            <telia-button
              :text="t('CANCEL')"
              variant="secondary"
              size="sm"
              full-width
              :disabled="isSubmitting"
              @click="closeRemoveConfirmation"
            />
          </div>
        </div>
      </section>
    </div>

    <section class="notification-wrapper">
      <div v-if="isSubmitting">
        <b2x-spinner size="small" t-id="b2x-spinner" />
      </div>
      <notification
        v-if="status !== 'success' && !isTeliaAdmin"
        :status="status"
        :heading="t(`NOTIFICATION.remove.${status}.heading`)"
        :message="t(`NOTIFICATION.remove.${status}.message`)"
        :a11y-message="t(`NOTIFICATION.a11yStatus.${status}`)"
      />
      <notification
        v-if="isTeliaAdmin"
        status="information"
        :heading="t(`NOTIFICATION.add.imitate.heading`)"
        :a11y-message="t(`NOTIFICATION.a11yStatus.information`)"
      />
    </section>
  </div>
</template>

<script>
import { corpGoodiesService } from "@telia/b2b-rest-client";
import { formatPhoneNumber } from "@telia/b2x-phone-number-format";
import { eventAction, trackBenefitsEvent } from "../../helper/benefitTracker";
import {
  trackBenefitRemoveProgress,
  trackBenefitRemoveComplete,
  trackBenefitRemoveFail,
} from "../../helper/analyticsManager";
import { translateMixin } from "../../locale";
import benefitsInfo from "./benefits-info.vue";
import notification from "../notification.vue";
import benefitsRemoveConfirmation from "./benefits-remove-confirmation.vue";

const initialState = () => {
  return {
    isInRemoveConfirmation: false,
    isSubmitting: false,
    status: null,
    shouldRemoveDatasimService: null,
    datasimEmailSelection: {
      value: "",
      valid: false,
    },
    formatPhoneNumber,
  };
};

export default {
  name: "benefits-remove-configuration",
  mixins: [translateMixin],
  components: {
    benefitsInfo,
    notification,
    benefitsRemoveConfirmation,
  },
  props: {
    selectedSubscription: {
      type: Object,
      required: true,
    },
    scopeId: {
      type: String,
      required: true,
    },
    selectedTscid: {
      type: String,
      required: true,
    },
    isTeliaAdmin: {
      type: Boolean,
      default: false,
    },
    renderComponent: {
      type: Boolean,
      required: true,
    },
    inRemovalConfirmation: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return initialState();
  },
  computed: {
    getBenefit() {
      return this.selectedSubscription.currentHoldings[0];
    },
    isDatasimBenefit() {
      return this.getBenefit.name === "business_plus_datasim";
    },
    isWithSecureBenefit() {
      return this.getBenefit.name === "business_plus_withsecure";
    },
    disableOnSubmitAndSuccess() {
      return this.isSubmitting || this.status === "success";
    },
    canRemoveDatasimBenefit() {
      if (!this.isDatasimBenefit) return true;
      if (this.shouldRemoveDatasimService === false) return true;
      return this.shouldRemoveDatasimService !== null && this.datasimEmailSelection.valid;
    },
    benefitCanBeRemoved() {
      return !this.getBenefit.validChangeDate;
    },
  },
  methods: {
    emailInput(e) {
      this.datasimEmailSelection.value = e.target.value;
      this.datasimEmailSelection.valid = e.target.validity.valid;
    },
    closeRemoveConfirmation() {
      this.$emit("set-removal-confirmation", false);
      trackBenefitsEvent(eventAction.CLICK, "Closed remove benefit confirmation");
    },
    showRemoveConfirmation() {
      this.$emit("set-removal-confirmation", true);
      trackBenefitsEvent(eventAction.INITIATED, `Remove benefit - ${this.getBenefit.name}`);
    },
    async handleBenefit() {
      if (!this.benefitCanBeRemoved) return;
      try {
        this.setSubmitState();
        trackBenefitRemoveProgress(this.getBenefit.name);
        const response = await this.removeBenefit();
        this.removalDetails = {
          type: this.getBenefit.name,
          orderNumber: response.orderNumber,
          c2bUid: this.selectedSubscription.c2bUid,
        };
        this.handleSubmitSuccess();
        trackBenefitRemoveComplete(this.getBenefit.name);
        this.setOngoing();
      } catch (e) {
        this.handleSubmitError();
        trackBenefitRemoveFail("technical_error");
      } finally {
        this.isSubmitting = false;
        this.$emit("is-submitting", false);
        this.closeRemoveConfirmation();
      }
    },
    async removeBenefit() {
      const benefit = this.getBenefit;
      let requestBody = {
        parentAssetIdentifier: this.selectedSubscription.msisdn,
        name: benefit.name,
        assetIdentifier: benefit.msisdn,
        serviceValue: benefit.serviceValue,
        productCategory: benefit.productCategory,
      };
      if (this.shouldRemoveDatasimService) {
        requestBody.c2bUid = this.selectedSubscription.c2bUid;
        requestBody.removeDatasimEmail = this.datasimEmailSelection.value;
      }
      return await corpGoodiesService.GoodiesControllerService.deleteBenefitUsingPost(
        requestBody,
        this.scopeId,
        this.selectedTscid
      );
    },
    setSubmitState() {
      this.isSubmitting = true;
      this.$emit("is-submitting", this.isSubmitting);
      trackBenefitsEvent(eventAction.CLICK, `Confirmed remove benefit - ${this.getBenefit.name}`);
    },
    handleSubmitSuccess() {
      this.status = "success";
    },
    handleSubmitError() {
      this.status = "error";
    },
    setOngoing() {
      this.$emit("set-ongoing", {
        id: this.selectedSubscription.c2bUid,
        currentHolding: {
          description: this.t(`BENEFITS.${this.getBenefit.name}`),
          name: this.getBenefit.name,
          ongoingOrder: true,
        },
      });
    },
    resetState() {
      Object.assign(this.$data, initialState());
    },
    closeModal() {
      this.$emit("close-modal");
    },
  },
  watch: {
    selectedSubscription: {
      handler() {
        this.resetState();
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables";
@import "~@teliads/components/foundations/breakpoints/variables";

.button-wrapper {
  margin-bottom: $telia-spacing-24;
  div:first-of-type {
    margin-bottom: $telia-spacing-16;
  }
}
.remove-header {
  margin-bottom: $telia-spacing-16;
}
.info-wrapper {
  margin-bottom: $telia-spacing-24;
  > telia-heading {
    margin-bottom: $telia-spacing-16;
  }
  .remove-datasim-service {
    .radio-button-wrapper {
      margin-bottom: $telia-spacing-16;
      > telia-p {
        margin-top: $telia-spacing-8;
      }
    }
  }
}
.button-wrapper {
  margin-bottom: $telia-spacing-24;
  div:first-of-type {
    margin-bottom: $telia-spacing-16;
  }
}
.verify-remove-benefit {
  padding: $telia-spacing-24 0;
}
.notification-wrapper {
  margin-bottom: $telia-spacing-24;
  margin-top: $telia-spacing-12;

  > div {
    display: flex;
    justify-content: center;
  }
}
.valid-remove-date-wrapper {
  margin-bottom: $telia-spacing-24;
}
</style>
