<template>
  <div v-if="renderComponent" class="benefit-configuration">
    <section v-if="status !== 'success'">
      <benefits-info :selected-subscription="selectedSubscription" />

      <section v-if="hasAvailableBenefits" class="select-wrapper">
        <div class="select-benefit">
          <telia-select
            :options="JSON.stringify(benefitOptions)"
            :disabled="disableOnSubmitAndSuccess"
            @change="selectBenefit($event.target.value)"
          />
        </div>

        <configure-withsecure
          v-if="selectedBenefit === Benefit.WITHSECURE"
          :selectedSubscription="selectedSubscription"
          :scope-id="scopeId"
          :selected-tscid="selectedTscid"
          :is-telia-admin="isTeliaAdmin"
          @close-modal="closeModal()"
          @is-submitting="handleSubmitState($event)"
          @has-ordered="handleConfirmOrder($event)"
          @set-status="handleStatusState($event)"
        />
        <configure-databucket
          v-if="selectedBenefit === Benefit.DATABUCKET"
          :selectedSubscription="selectedSubscription"
          :scope-id="scopeId"
          :selected-tscid="selectedTscid"
          :is-telia-admin="isTeliaAdmin"
          @close-modal="closeModal()"
          @is-submitting="handleSubmitState($event)"
          @has-ordered="handleConfirmOrder($event)"
          @set-status="handleStatusState($event)"
        />
        <configure-datasim
          v-else-if="selectedBenefit === Benefit.DATASIM"
          :selectedSubscription="selectedSubscription"
          :scope-id="scopeId"
          :selected-tscid="selectedTscid"
          :is-telia-admin="isTeliaAdmin"
          @close-modal="closeModal()"
          @is-submitting="handleSubmitState($event)"
          @has-ordered="handleConfirmOrder($event)"
          @set-status="handleStatusState($event)"
        />

        <section v-if="selectedBenefit">
          <section class="terms-wrapper">
            <telia-p variant="additional-100">
              {{ t("TERMS") }}
              <telia-link variant="text" href="/foretag/mybusiness/om/villkor-mybusiness">
                {{ t("MY_BUSINESS_TERMS") }}
              </telia-link>
            </telia-p>
          </section>

          <section class="notification-wrapper">
            <div v-if="isSubmitting">
              <b2x-spinner size="small" t-id="b2x-spinner" />
            </div>
            <notification
              v-if="!isTeliaAdmin"
              :status="status"
              :heading="t(`NOTIFICATION.add.${status}.heading`)"
              :message="t(`NOTIFICATION.add.${status}.message`)"
              :a11y-message="t(`NOTIFICATION.a11yStatus.${status}`)"
            />
            <notification
              v-if="isTeliaAdmin"
              status="information"
              :heading="t(`NOTIFICATION.add.imitate.heading`)"
              :a11y-message="t(`NOTIFICATION.a11yStatus.information`)"
            />
          </section>
        </section>
      </section>
      <section v-else class="no-available-benefits">
        <notification
          status="information"
          :heading="t('NO_AVAILABLE_BENEFITS.HEADING')"
          :message="t('NO_AVAILABLE_BENEFITS.MESSAGE')"
          :a11y-message="t(`NOTIFICATION.a11yStatus.information`)"
        />
      </section>
    </section>
    <section v-else>
      <benefits-add-confirmation
        :scopeId="scopeId"
        :selectedSubscription="selectedSubscription"
        :order-details="orderDetails"
        @close-modal="closeModal()"
      />
    </section>
  </div>
</template>

<script>
import { translateMixin } from "../../locale";
import configureDatabucket from "./configure-databucket.vue";
import configureDatasim from "./configure-datasim.vue";
import configureWithsecure from "./configure-withsecure.vue";
import notification from "../notification.vue";
import benefitsInfo from "./benefits-info.vue";
import benefitsAddConfirmation from "./benefits-add-confirmation.vue";
import { eventAction, trackBenefitsConfigEvent } from "../../helper/benefitTracker";
import { addProgress } from "../../helper/analyticsManager";

const Benefit = Object.freeze({
  DATABUCKET: "business_plus_databucket",
  DATASIM: "business_plus_datasim",
  WITHSECURE: "business_plus_withsecure",
});
const initialState = () => {
  return {
    Benefit,
    selectedBenefit: null,
    isSubmitting: false,
    status: null,
  };
};
export default {
  name: "benefits-add-configuration",
  mixins: [translateMixin],
  components: {
    configureWithsecure,
    configureDatasim,
    configureDatabucket,
    benefitsInfo,
    notification,
    benefitsAddConfirmation,
  },
  props: {
    selectedSubscription: {
      type: Object,
      required: true,
    },
    scopeId: {
      type: String,
      required: true,
    },
    selectedTscid: {
      type: String,
      required: true,
    },
    isTeliaAdmin: {
      type: Boolean,
      default: false,
    },
    renderComponent: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      Benefit,
      selectedBenefit: null,
      isSubmitting: false,
      status: null,
      orderDetails: null,
    };
  },
  computed: {
    benefitOptions() {
      const filteredSubscriptions = [
        ...new Set(this.selectedSubscription.orderOnAssetSelection?.map((i) => i.name)),
      ];

      return [
        {
          label: this.t("SELECT_BENEFIT"),
          value: "",
          selected: true,
          disabled: true,
        },
        ...filteredSubscriptions.map((s) => ({
          value: s,
          label: this.t(`BENEFITS.${s}`),
        })),
      ];
    },
    hasAvailableBenefits() {
      return this.selectedSubscription.orderOnAssetSelection.length > 0;
    },
    disableOnSubmitAndSuccess() {
      return this.isSubmitting;
    },
  },
  methods: {
    handleSubmitState(event) {
      this.isSubmitting = event;
      this.$emit("is-submitting", this.isSubmitting);
    },
    handleStatusState(event) {
      this.status = event;

      if (this.status === "success") {
        this.$emit("set-ongoing", {
          id: this.selectedSubscription.c2bUid,
          currentHolding: {
            description: this.t(`BENEFITS.${this.selectedBenefit}`),
            name: this.selectedBenefit,
            ongoingOrder: true,
          },
        });
      }
    },
    handleConfirmOrder(event) {
      this.orderDetails = event;
    },
    resetState() {
      Object.assign(this.$data, initialState());
    },
    selectBenefit(event) {
      this.selectedBenefit = event;
      trackBenefitsConfigEvent(eventAction.SELECTED, this.selectedBenefit);
      addProgress(this.selectedBenefit);
    },
    closeModal() {
      this.$emit("close-modal");
    },
  },
  watch: {
    selectedSubscription: {
      handler() {
        this.resetState();
        this.selectedBenefit = this.benefitOptions[0]?.value;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables";

.no-available-benefits {
  margin-bottom: $telia-spacing-24;
}
.select-wrapper {
  margin-bottom: $telia-spacing-24;
  .select-benefit {
    margin-bottom: $telia-spacing-24;
  }
}
.terms-wrapper {
  padding-left: $telia-spacing-8;
}
.notification-wrapper {
  margin-bottom: $telia-spacing-24;
  margin-top: $telia-spacing-12;

  > div {
    display: flex;
    justify-content: center;
  }
}
</style>
