<template>
  <section>
    <section class="order-confirmation__section">
      <div class="order-confirmation__header">
        <telia-icon name="check-circle"></telia-icon>
        <telia-heading class="order-confirmation__title" tag="h1" variant="title-100">
          {{ t("CONFIRMATION.ORDER_TITLE") }}
        </telia-heading>
      </div>
      <div>
        <telia-p>{{ t("CONFIRMATION.ORDER_TEXT") }}</telia-p>
      </div>
    </section>

    <section class="order-confirmation__section">
      <div v-if="isDatasimBenefit" class="order-confirmation__info">
        <telia-heading class="order-confirmation__section__title" tag="h3" variant="subsection-100">
          {{ t("BENEFITS." + orderDetails.type) }}
        </telia-heading>
        <telia-p>{{ formatPhoneNumber(getMsisdn()) }}</telia-p>
        <telia-p> {{ orderDetails.email }}</telia-p>
        <telia-p v-if="orderDetails.address">{{ t("CONFIRMATION.DELIVERY") }}</telia-p>
      </div>

      <div v-if="orderDetails.address">
        <telia-heading class="order-confirmation__section__title" tag="h3" variant="subsection-100">
          {{ t("CONFIRMATION.DELIVERY_ADDRESS") }}
        </telia-heading>
        <telia-p>{{ orderDetails.address.name }}</telia-p>
        <telia-p>{{ orderDetails.address.street }} </telia-p>
        <telia-p>{{ orderDetails.address.zipCode }} {{ orderDetails.address.city }}</telia-p>
      </div>
    </section>

    <section class="order-confirmation__section">
      <telia-heading class="order-confirmation__section__title" tag="h3" variant="subsection-100">
        {{ t("CONFIRMATION.ORDER_NUMBER") }}
      </telia-heading>
      <telia-p class="order-confirmation__info"> {{ orderDetails.orderNumber }}</telia-p>
      <telia-heading class="order-confirmation__section__title" tag="h3" variant="subsection-100">
        {{ t("ACTIVATION_DATE") }}
      </telia-heading>
      <telia-p class="order-confirmation__info">
        {{ new Intl.DateTimeFormat("sv-SE").format(new Date()) }}
      </telia-p>
    </section>

    <section class="order-confirmation__section">
      <telia-p class="order-confirmation__info">
        <strong>{{ t("CONFIRMATION.OBSERVE") }}</strong>
        {{ t("CONFIRMATION.CHANGE_INFO") }}
      </telia-p>
      <telia-p>{{ t("CONFIRMATION.CONDITION") }} </telia-p>
      <telia-p>
        <telia-link
          variant="text"
          :href="`/foretag/mybusiness/${scopeId}/hantera/produkter-tjanster/mobila-abonnemang/${selectedSubscription.c2bUid}`"
        >
          {{ t("CONFIRMATION.HANDLE") }}
        </telia-link>
      </telia-p>
    </section>
    <div>
      <telia-button
        :text="t('CLOSE')"
        :ally-label="t('CLOSE')"
        variant="secondary"
        size="sm"
        full-width
        type="button"
        @click="closeModal()"
      />
    </div>
  </section>
</template>

<script>
import { translateMixin } from "../../locale";
import { formatPhoneNumber } from "@telia/b2x-phone-number-format";
import { setOrderNotificationTimer } from "../../helper/orderNotificationManager";

export default {
  name: "benefits-add-confirmation",
  mixins: [translateMixin],
  props: {
    scopeId: {
      type: String,
      required: true,
    },
    selectedSubscription: {
      type: Object,
      required: true,
    },
    orderDetails: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      formatPhoneNumber,
    };
  },
  computed: {
    isDatasimBenefit() {
      return this.orderDetails.type === "business_plus_datasim";
    },
  },
  mounted() {
    setOrderNotificationTimer();
  },
  methods: {
    getMsisdn() {
      return this.orderDetails.msisdn ?? this.selectedSubscription.msisdn;
    },
    closeModal() {
      this.$emit("close-modal");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "node_modules/@teliads/components/foundations/spacing/variables";
.order-confirmation {
  &__header {
    display: flex;
    align-items: center;
    telia-icon {
      margin-bottom: $telia-spacing-8;
      margin-right: $telia-spacing-8;
    }
  }
  &__section {
    &__title {
      margin-bottom: 0;
    }
    margin-bottom: $telia-spacing-24;
  }
  &__title {
    margin-bottom: $telia-spacing-8;
  }
  &__button {
    margin-top: $telia-spacing-16;
  }
  &__alert {
    margin-top: $telia-spacing-16;
  }
  &__list {
    list-style: none;
  }
  &__info {
    margin-bottom: $telia-spacing-16;
  }
}
</style>
