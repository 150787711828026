<template>
  <div class="page-container" data-dont-collect>
    <div>
      <guide-modal v-if="isGuideOpen" :open="isGuideOpen" @change="isGuideOpen = false" />
      <b2b-layout
        v-if="render"
        ref="layoutRef"
        :page-title="t('MY_BENEFITS')"
        :heading="t('MY_BENEFITS')"
        show-organisation-selector
        :has-tabs="true"
        :show-onboarding-button="isPrerequisitesFulfilled && isOrganizationEligible"
        :back-link-label="t('BACK_TO_PRODUCTS_AND_SERVICES')"
        :back-link-href="`/foretag/mybusiness/${scopeId}/bestall/produkter-tjanster`"
        :selected-organisation="selectedTscid"
        @organisationSelected="setAndCheckOrganization($event.detail)"
        @onboardingButtonClicked="openGuideModal"
      >
        <span
          v-if="
            isOrganizationEligible &&
            isPrerequisitesFulfilled &&
            isValidToOrder &&
            amountOfBenefitsAllowed
          "
          slot="header"
        >
          <telia-grid>
            <telia-p>
              {{ t("AMOUNT_OF_BENEFITS_REMAINING_PT1") }}
              <span class="purple">{{ amountOfBenefitsRemaining }}</span>
              {{ t("AMOUNT_OF_BENEFITS_REMAINING_PT2") }}
              {{ amountOfBenefitsAllowed }}
              {{ t("AMOUNT_OF_BENEFITS_REMAINING_PT3") }}
            </telia-p>
          </telia-grid>
        </span>
        <telia-grid class="overview-categories">
          <section>
            <telia-tab
              v-if="!isLoading"
              variant="contained-on-negative"
              :full-width="!isMobile"
              @vocaTabChange="setTabValue"
            >
              <telia-tab-content
                :tab-id="TABS.START"
                :name="t('BENEFIT')"
                :active="getTabValue(TABS.START)"
                icon="bulb"
              />
              <telia-tab-content
                v-if="isPrerequisitesFulfilled && isOrganizationEligible"
                :tab-id="TABS.BENEFITS"
                :name="t('MANAGE_BENEFITS')"
                :active="getTabValue(TABS.BENEFITS)"
                icon="present"
              />
            </telia-tab>
          </section>
        </telia-grid>

        <telia-grid>
          <section v-if="!isLoading && !loadingError && selectedTscid">
            <subscription-start-page
              v-show="getTabValue(TABS.START)"
              :scope-id="scopeId"
              :is-prerequisites-fulfilled="isPrerequisitesFulfilled"
              :is-organization-eligible="isOrganizationEligible"
              @switch-tab="setTabValue"
            />
            <subscription-overview
              v-show="getTabValue(TABS.BENEFITS)"
              :scope-id="scopeId"
              :selected-tscid="selectedTscid"
              :is-telia-admin="isTeliaAdmin"
              :isMobile="isMobile"
              @isValidToOrder="isValidToOrder = $event"
              @amountOfBenefitsRemaining="amountOfBenefitsRemaining = $event"
              @amountOfBenefitsAllowed="amountOfBenefitsAllowed = $event"
            />
          </section>
          <section v-else-if="!isLoading && loadingError" class="notification-container">
            <notification
              :status="'error'"
              :heading="t('NOTIFICATION.general.error.heading')"
              :message="t('NOTIFICATION.general.error.message')"
              :a11y-message="t('NOTIFICATION.a11yStatus.warning')"
            />
          </section>
        </telia-grid>
      </b2b-layout>
    </div>
  </div>
</template>

<script>
import { translateSetup, translateMixin } from "./locale";
import { getScopeIdOrThrow } from "@telia/b2b-customer-scope";
import { getOrganizations, isTeliaAdmin } from "@telia/b2b-logged-in-service";
import { corpAgreements } from "@telia/b2b-rest-client";
import { corpSegment } from "@telia/b2x-rest-client";
import { eventAction, trackBenefitsEvent } from "./helper/benefitTracker";
import { benefitsMultiOrgEnabled } from "./featureToggles.js";
import subscriptionOverview from "./components/subscription-overview.vue";
import subscriptionStartPage from "./components/subscription-start-page.vue";
import guideModal from "./components/guide-modal.vue";
import notification from "./components/notification.vue";

const TABS = {
  START: "start",
  BENEFITS: "benefits",
};

export default {
  name: "b2b-benefits",
  mixins: [translateMixin],
  components: {
    subscriptionOverview,
    subscriptionStartPage,
    guideModal,
    notification,
  },

  data() {
    return {
      TABS,
      scopeId: null,
      organizations: [],
      selectedTscid: null,
      selectedTab: TABS.BENEFITS,
      isTeliaAdmin: false,
      isPrerequisitesFulfilled: false,
      isOrganizationEligible: false,
      isLoading: true,
      loadingError: false,
      isGuideOpen: false,
      isMobile: false,
      isValidToOrder: false,
      amountOfBenefitsRemaining: 0,
      amountOfBenefitsAllowed: 0,
      render: false,
    };
  },

  async created() {
    await translateSetup(["mybusiness"]);
    this.isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  },
  async mounted() {
    try {
      const [scopeId, teliaAdmin, orgs] = await Promise.all([
        getScopeIdOrThrow(),
        isTeliaAdmin(),
        getOrganizations(),
      ]);

      this.scopeId = scopeId;
      this.isTeliaAdmin = teliaAdmin;
      this.organizations = orgs;
      this.render = true;
    } catch {
      this.isLoading = false;
      this.loadingError = true;
      this.render = true;
    }
  },
  methods: {
    setTabValue(event) {
      this.selectedTab = event.detail.value;

      if (this.selectedTab === TABS.BENEFITS) {
        trackBenefitsEvent(eventAction.CLICK, "Manage benefits");
      }
    },
    getTabValue(value) {
      return this.selectedTab === value;
    },
    async setAndCheckOrganization(value) {
      this.isLoading = true;
      this.selectedTscid = value;

      try {
        this.amountOfBenefitsRemaining = 0;
        this.amountOfBenefitsAllowed = 0;

        await this.checkOrganizationEligibility();
        await this.checkPrerequisites();

        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
        this.loadingError = true;
      }
    },
    async checkPrerequisites() {
      this.isPrerequisitesFulfilled = false;
      let isMeetingBenefitsPrerequisites = false;
      const isSingleOrganization = this.organizations.length === 1;
      const isSRACustomer = this.scopeId.startsWith("8");

      if (benefitsMultiOrgEnabled()) {
        isMeetingBenefitsPrerequisites = isSRACustomer;
      } else {
        isMeetingBenefitsPrerequisites = isSingleOrganization && isSRACustomer;
      }

      if (isMeetingBenefitsPrerequisites) {
        const permissionData = await corpSegment.PublicService.getSegment1(this.scopeId);

        const isMeetingSegmentPrerequisites =
          permissionData.results.mybusinessSegment === "SME" ||
          permissionData.results.mybusinessSegment === "SOHO";

        if (isMeetingSegmentPrerequisites) {
          this.isPrerequisitesFulfilled = true;
        }
      } else {
        this.selectedTab = TABS.START;
      }
    },
    async checkOrganizationEligibility() {
      this.isOrganizationEligible = false;
      const mobileAgreements = await corpAgreements.MobileAgreementsControllerService.getMobileAgreementsNewAuth(
        this.scopeId,
        {
          agreementLevels: ["RAM", "UNDER"],
          tscId: this.selectedTscid,
        }
      );

      if (mobileAgreements.results.length === 0) {
        this.isOrganizationEligible = true;
        return;
      }

      for (let i = 0; i < mobileAgreements.results.length; i++) {
        if (mobileAgreements.results[i].agreementType === "SME") {
          this.isOrganizationEligible = true;
          return;
        }
      }
      this.selectedTab = TABS.START;
    },
    openGuideModal() {
      this.isGuideOpen = true;
      trackBenefitsEvent(eventAction.CLICK, "Guide");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables";
@import "~@teliads/components/foundations/colors/variables";

.page-container {
  margin-bottom: $telia-spacing-64;
}
.overview-categories {
  margin-top: -4.2rem;
}
.notification-container {
  max-width: 50rem;
}
.access-warning-container {
  padding: $telia-spacing-16 0 $telia-spacing-64;
}
.purple {
  color: $telia-purple-500;
}
</style>
