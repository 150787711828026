<template>
  <section class="configure-datasim">
    <div v-if="!canOrderNewDatasim" class="max-amount-wrapper">
      <telia-p>
        {{ t("DATASIM.REACHED_MAX_AMOUNT_OF_DATASIM_PART_1") }}
        <telia-link variant="text" href="/foretag/mybusiness/hantera\">
          {{ t("DATASIM.REACHED_MAX_AMOUNT_OF_DATASIM_PART_2") }}
        </telia-link>
        {{ t("DATASIM.REACHED_MAX_AMOUNT_OF_DATASIM_PART_3") }}
      </telia-p>
    </div>

    <section class="main-selection-wrapper">
      <section v-if="canOrderNewDatasim">
        <div
          :class="getFilterSelection(SIM_FORMAT.SIM_CARD) ? 'active' : ''"
          @click.prevent="setFilterSelection(SIM_FORMAT.SIM_CARD)"
        >
          <telia-radio-button
            :label="t('DATASIM.DATASIM')"
            :checked="getFilterSelection(SIM_FORMAT.SIM_CARD)"
          />
        </div>
        <div
          :class="getFilterSelection(SIM_FORMAT.ESIM) ? 'active' : ''"
          @click.prevent="setFilterSelection(SIM_FORMAT.ESIM)"
        >
          <telia-radio-button
            :label="t('DATASIM.ESIM')"
            :checked="getFilterSelection(SIM_FORMAT.ESIM)"
          />
        </div>
      </section>
      <section v-if="hasExistingDatasim">
        <div
          :class="getFilterSelection(SIM_FORMAT.KEEP) ? 'active' : ''"
          @click.prevent="setFilterSelection(SIM_FORMAT.KEEP)"
        >
          <telia-radio-button
            :label="t('DATASIM.USE_EXISTING')"
            :checked="getFilterSelection(SIM_FORMAT.KEEP)"
          />
        </div>
      </section>
    </section>

    <section class="content-wrapper">
      <div v-if="getFilterSelection(SIM_FORMAT.SIM_CARD)" class="datasim-wrapper">
        <!--
        The option to use existing ICC is confusing to customers and leads to more negatives than positives.
        Deactivated until we can figure out a good solution.
        With one option deactivated we only have one left and can set that automatically and hide this section.

        <div class="datasim-selection">
          <div>
            <telia-radio-button
              :label="t('DATASIM.NEW_DATASIM')"
              :disabled="disableOnSubmitAndSuccess"
              :checked="getDatasimSelection(DATASIM.NEW_DATASIM)"
              @change="setDatasimSelection(DATASIM.NEW_DATASIM)"
            />
          </div>

          <div>
            <telia-radio-button
              :label="t('DATASIM.NEW_DATASIM_EXISTING_ICC')"
              :disabled="disableOnSubmitAndSuccess"
              :checked="getDatasimSelection(DATASIM.NEW_DATASIM_EXISTING_ICC)"
              @change="setDatasimSelection(DATASIM.NEW_DATASIM_EXISTING_ICC)"
            />
          </div>
        </div>
        -->
        <div v-if="datasimSelection" class="datasim-info">
          <div v-if="getDatasimSelection(DATASIM.NEW_DATASIM)">
            <telia-p class="info-paragraph">{{ t("DATASIM.DATASIM_DELIVERY_TIME") }}</telia-p>
            <telia-p class="info-paragraph">
              <strong>{{ t("DATASIM.SIM_CARD_SENT_TO") }}</strong>
              <telia-p>{{ shippingAddress.name }}</telia-p>
              <telia-p>{{ `${shippingAddress.street}` }}</telia-p>
              <telia-p>{{ `${shippingAddress.postalCode} ${shippingAddress.city}` }}</telia-p>
            </telia-p>
            <telia-p class="info-paragraph">{{ t("DATASIM.DATASIM_INFO") }}</telia-p>
            <telia-p>{{ t("DATASIM.ADDRESS_INFO") }}</telia-p>
          </div>

          <div v-if="getDatasimSelection(DATASIM.NEW_DATASIM_EXISTING_ICC)">
            <telia-p class="info-paragraph" disable-hyphenation>
              {{ t("DATASIM.DATASIM_EXISTING_ICC_INFO") }}
            </telia-p>
            <benefits-icc-search
              :show-button="false"
              @submit="onIccNumberChecked"
              @valid="onValidIcc"
            />
          </div>
        </div>
      </div>

      <div v-if="getFilterSelection(SIM_FORMAT.ESIM)" class="datasim-wrapper">
        <div class="esim-qr-info">
          <telia-p class="info-paragraph">
            <strong>{{ t("DATASIM.QR_CODE_ACTIVATE") }}</strong>
            <br />{{ t("DATASIM.ESIM_INFO") }}<br />
          </telia-p>
        </div>

        <div class="datasim-selection">
          <div>
            <telia-radio-button
              v-if="emailSelection.userEmail"
              :label="t('DATASIM.QR_CODE_SELECTION_TO_ME')"
              :checked="getEsimSelection(ESIM_EMAIL.ORIGINAL)"
              @change="setEsimSelection(ESIM_EMAIL.ORIGINAL)"
            ></telia-radio-button>
          </div>

          <div>
            <telia-radio-button
              :label="t('DATASIM.QR_CODE_SELECTION_TO_ELSE')"
              :checked="getEsimSelection(ESIM_EMAIL.NEW)"
              @change="setEsimSelection(ESIM_EMAIL.NEW)"
            ></telia-radio-button>
          </div>
        </div>

        <div v-if="esimSelection" class="datasim-info">
          <telia-p class="info-paragraph" v-if="getEsimSelection(ESIM_EMAIL.ORIGINAL)">
            {{ t("DATASIM.ESIM_EMAIL", { email: emailSelection.userEmail }) }}
          </telia-p>

          <div v-if="getEsimSelection(ESIM_EMAIL.NEW)">
            <telia-text-input
              :value="emailSelection.newEmail"
              @input="emailSelection.newEmail = $event.target.value"
              pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$"
              name="email"
              type="email"
              maxlength="50"
              :label="t('DATASIM.ESIM_ENTER_EMAIL')"
              :required-error-message="t('ERRORS.EMAIL')"
              :email-error-message="t('ERRORS.EMAIL_INVALID')"
              required
            />
          </div>
        </div>
      </div>

      <div v-if="getFilterSelection(SIM_FORMAT.KEEP)" class="datasim-wrapper">
        <telia-p>{{ t("DATASIM.REMOVE_EXISTING") }}</telia-p>
        <div class="datasim-info remove-cost">
          <div v-for="(sim, i) in existingDatasim" :key="i" class="existing-sim-choice">
            <telia-chip-choice
              :text="sim.label"
              :value="sim.msisdn"
              :checked="getExistingDatasimSelection(sim.msisdn)"
              @click="setExistingDatasimSelection(sim.msisdn)"
            />
          </div>
        </div>
      </div>
    </section>

    <section class="button-wrapper">
      <div>
        <telia-button
          :text="t('ACTIVATE')"
          :ally-label="t('A11Y.ACTIVATE_BENEFIT')"
          t-id="submit-button"
          variant="primary"
          size="sm"
          full-width
          type="button"
          :disabled="disableSubmitButton"
          @click="addDatasimBenefit()"
        />
      </div>

      <div>
        <telia-button
          :text="t('CLOSE')"
          :ally-label="t('A11Y.CANCEL_ORDER')"
          variant="secondary"
          size="sm"
          full-width
          type="button"
          :disabled="isSubmitting"
          @click="closeModal()"
        />
      </div>
    </section>
  </section>
</template>

<script>
import { corpGoodiesService, corpMobileSubscriptionInfo } from "@telia/b2b-rest-client";
import { getLoggedInUserInfo } from "@telia/b2b-logged-in-service";
import { formatPhoneNumber } from "@telia/b2x-phone-number-format";
import { translateMixin } from "../../locale";
import { trackAddBenefit, trackSimFormat } from "../../helper/benefitTracker";
import benefitsIccSearch from "./benefits-icc-search.vue";
import { trackSuccessfulOrder, trackFailedOrder } from "../../helper/analyticsManager";

const DATASIM = Object.freeze({
  NEW_DATASIM: "new-datasim",
  NEW_DATASIM_EXISTING_ICC: "new-datasim-existing-icc",
});

const ESIM = Object.freeze({
  EMAIL: "email",
  QR_CODE: "qr-code",
});

const ESIM_EMAIL = Object.freeze({
  ORIGINAL: "original email",
  NEW: "new email",
});

const SIM_FORMAT = Object.freeze({
  SIM_CARD: "simcard",
  ESIM: "esim",
  KEEP: "keep",
});

const MAX_AMOUNT_OF_DATASIM = 4;

export default {
  name: "configure-datasim",
  mixins: [translateMixin],
  components: {
    benefitsIccSearch,
  },
  props: {
    selectedSubscription: {
      type: Object,
      required: true,
    },
    scopeId: {
      type: String,
      required: true,
    },
    selectedTscid: {
      type: String,
      required: true,
    },
    isTeliaAdmin: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      DATASIM,
      ESIM,
      ESIM_EMAIL,
      SIM_FORMAT,
      isSubmitting: false,
      status: null,
      filterSelection: null,
      datasimSelection: null,
      esimSelection: null,
      selectedExistingDatasim: null,
      shippingAddress: {
        name: null,
        street: null,
        postalCode: null,
        city: null,
      },
      selectedIcc: null,
      iccValid: false,
      emailSelection: {
        userEmail: "",
        newEmail: "",
        selectNewEmail: false,
      },
    };
  },

  computed: {
    disableSubmitButton() {
      return (
        this.disableOnSubmitAndSuccess ||
        this.isTeliaAdmin ||
        !this.filterSelection ||
        (!this.datasimSelection && !this.esimSelection && !this.selectedExistingDatasim) ||
        (!this.iccValid && this.datasimSelection === DATASIM.NEW_DATASIM_EXISTING_ICC) ||
        (this.emailSelection.selectNewEmail && !this.emailSelection.newEmail)
      );
    },
    disableOnSubmitAndSuccess() {
      return this.isSubmitting || this.status === "success";
    },
    existingDatasim() {
      return this.selectedSubscription.orderOnAssetSelection
        .filter(
          (el) =>
            el.msisdn &&
            el.msisdn !== this.selectedSubscription.msisdn &&
            el.name === "business_plus_datasim"
        )
        .map((el) => ({ ...el, label: formatPhoneNumber(el.msisdn) }));
    },
    canOrderNewDatasim() {
      return this.existingDatasim.length < MAX_AMOUNT_OF_DATASIM;
    },
    hasExistingDatasim() {
      return this.existingDatasim.length > 0;
    },
  },

  async mounted() {
    await this.getData();
  },

  methods: {
    async getData() {
      try {
        const [userInfo, subscriptionInfo] = await Promise.allSettled([
          getLoggedInUserInfo(),
          corpMobileSubscriptionInfo.MobileSubscriptionInfoControllerService.getSubscriptionInfo(
            this.selectedSubscription.c2bUid,
            this.scopeId
          ),
        ]);

        const trimmedPostalCode = subscriptionInfo.value.subscriptionAddress.postalCode
          ? subscriptionInfo.value.subscriptionAddress.postalCode.replaceAll(" ", "")
          : "";

        this.emailSelection.userEmail = userInfo.value.mail;
        this.shippingAddress.name = subscriptionInfo.value.subscriptionAddress.name;
        this.shippingAddress.street = subscriptionInfo.value.subscriptionAddress.street;
        this.shippingAddress.postalCode = trimmedPostalCode;
        this.shippingAddress.city = subscriptionInfo.value.subscriptionAddress.city;
      } catch (e) {
        this.handleSubmitError();
        trackFailedOrder("benefit_add_selected", "business_plus_datasim");
      } finally {
        this.$emit("set-status", this.status);
      }
    },
    async addDatasimBenefit() {
      if (this.datasimSelection === DATASIM.NEW_DATASIM_EXISTING_ICC && !this.iccValid) {
        return;
      }
      this.setSubmitState();
      try {
        const response = await corpGoodiesService.GoodiesControllerService.addBenefitUsingPost(
          this.orderData(),
          this.scopeId,
          this.selectedTscid
        );
        this.handleSubmitSuccess();
        trackAddBenefit("business_plus_datasim");
        trackSuccessfulOrder("business_plus_datasim");
        trackSimFormat(this.filterSelection);
        let orderDetails = { type: "business_plus_datasim", orderNumber: response.orderNumber };
        if (this.datasimSelection === DATASIM.NEW_DATASIM) {
          orderDetails.address = this.shippingAddress;
        }
        if (this.filterSelection === SIM_FORMAT.KEEP) {
          orderDetails.msisdn = this.selectedExistingDatasim;
        }
        this.$emit("has-ordered", orderDetails);
      } catch (e) {
        this.handleSubmitError();
        trackFailedOrder("benefit_add_selected", "business_plus_datasim");
      } finally {
        this.$emit("set-status", this.status);
        this.isSubmitting = false;
        this.$emit("is-submitting", this.isSubmitting);
      }
    },
    orderData() {
      const datasimSelection = this.selectedSubscription?.orderOnAssetSelection?.find(
        (el) => el.name === "business_plus_datasim"
      );
      const icc =
        this.datasimSelection === DATASIM.NEW_DATASIM_EXISTING_ICC && this.iccValid
          ? this.selectedIcc
          : null;
      const email = this.emailSelection.selectNewEmail
        ? this.emailSelection.newEmail
        : this.emailSelection.userEmail;

      return {
        name: datasimSelection.name,
        productCategory: "mobilebroadbandsubscription",
        parentAssetIdentifier: this.selectedSubscription.msisdn,
        serviceValue: datasimSelection.serviceValue,
        assetIdentifier: this.selectedExistingDatasim,
        shippingAddress: this.selectedExistingDatasim ? null : this.shippingAddress,
        simFormat: this.filterSelection,
        esimMethod: ESIM.EMAIL,
        esimEmail: email,
        c2bUid: this.selectedSubscription.c2bUid,
        iccNumber: icc,
      };
    },
    getFilterSelection(selection) {
      return this.filterSelection === selection;
    },
    setFilterSelection(selection) {
      this.resetEmailSelection();
      this.resetSelections();
      this.filterSelection = selection;

      // TODO: Since only one option is available after deactivation of existing sim option we can set it automatically
      if (this.filterSelection === SIM_FORMAT.SIM_CARD) {
        this.setDatasimSelection(DATASIM.NEW_DATASIM);
      }
      if (this.filterSelection === SIM_FORMAT.ESIM && !this.emailSelection.userEmail) {
        this.setEsimSelection(ESIM_EMAIL.NEW);
      }
    },

    getDatasimSelection(selection) {
      return this.datasimSelection === selection;
    },
    setDatasimSelection(selection) {
      this.datasimSelection = selection;
    },

    getEsimSelection(selection) {
      return this.esimSelection === selection;
    },
    setEsimSelection(selection) {
      this.resetEmailSelection();
      this.esimSelection = selection;
      this.emailSelection.selectNewEmail = selection === ESIM_EMAIL.NEW;
    },

    getExistingDatasimSelection(msisdn) {
      return this.selectedExistingDatasim === msisdn;
    },
    setExistingDatasimSelection(msisdn) {
      this.selectedExistingDatasim = this.selectedExistingDatasim === msisdn ? null : msisdn;
    },

    resetSelections() {
      this.selectedExistingDatasim = null;
      this.datasimSelection = null;
      this.esimSelection = null;
    },
    resetEmailSelection() {
      this.emailSelection.selectNewEmail = false;
      this.emailSelection.newEmail = "";
    },
    setSubmitState() {
      this.isSubmitting = true;
      this.$emit("is-submitting", this.isSubmitting);
    },
    handleSubmitSuccess() {
      this.status = "success";
    },
    handleSubmitError() {
      this.status = "error";
    },
    closeModal() {
      this.$emit("close-modal");
    },
    onIccNumberChecked(event) {
      this.selectedIcc = event.icc;
    },
    onValidIcc(event) {
      this.iccValid = event.valid;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/borders/variables";
@import "~@teliads/components/foundations/colors/variables";
@import "~@teliads/components/foundations/motion/variables";
@import "~@teliads/components/foundations/shadows/variables";
@import "~@teliads/components/foundations/spacing/variables";

.content-wrapper,
.max-amount-wrapper {
  margin-bottom: $telia-spacing-24;
}
.main-selection-wrapper {
  margin-bottom: $telia-spacing-24;

  div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    height: $telia-spacing-48;
    margin-bottom: $telia-spacing-16;
    border: $telia-border-width-1 solid $telia-gray-300;
    border-radius: $telia-border-radius-16;
    transition: all $telia-duration-300 $telia-ease-in-out;
    > telia-radio-button {
      margin-left: $telia-spacing-16;
    }
    &.active {
      border-color: $telia-purple-500;
    }
    &:hover {
      border-color: $telia-purple-500;
      box-shadow: $telia-shadow-md;
    }
  }
}

.datasim-wrapper {
  .esim-qr-info {
    margin-bottom: $telia-spacing-16;
  }
  .datasim-selection {
    display: flex;
    flex-direction: column;

    > div:first-of-type {
      margin-bottom: $telia-spacing-16;
    }
  }

  .datasim-info {
    background-color: $telia-gray-50;
    border: solid $telia-border-width-1 $telia-gray-200;
    border-radius: $telia-border-radius-8;
    padding: $telia-spacing-16;
    margin: $telia-spacing-16 0 0;

    &.remove-cost {
      display: flex;
      flex-wrap: wrap;

      .existing-sim-choice {
        margin-right: $telia-spacing-8;
        margin-bottom: $telia-spacing-8;
      }
    }
    .info-paragraph:not(:last-child) {
      margin-bottom: $telia-spacing-24;
      telia-p {
        margin-bottom: 0;
      }
    }
  }
}

.button-wrapper {
  margin-bottom: $telia-spacing-24;
  div:first-of-type {
    margin-bottom: $telia-spacing-16;
  }
}
</style>
