import { track, TeliaEventName } from "@telia/b2x-google-analytics";

const eventName = {
  Initiate: "benefit_add_initiate",
  Progress: "benefit_add_progress",
  Complete: "benefit_add_complete",
  Fail: "benefit_add_fail",
  RemoveInitiate: "benefit_remove_initiate",
  RemoveProgress: "benefit_remove_progress",
  RemoveComplete: "benefit_remove_complete",
  RemoveFail: "benefit_remove_fail",
  SurveyInitiate: "survey_initiate",
};

const segment = {
  business_area: "b2b",
  logged_in: true,
  page_segment: "mybusiness",
  page_type: "order",
};

export const trackInitiate = () => {
  track(eventName.Initiate as TeliaEventName, {
    ...segment,
    step_number: "1",
    step_label: "benefit_add_open",
  });
};

export const addProgress = (option: string) => {
  track(eventName.Progress as TeliaEventName, {
    ...segment,
    step_number: "2",
    step_label: "benefit_add_selected",
    step_option: option,
  });
};

export const trackSuccessfulOrder = (option: string) => {
  track(eventName.Complete as TeliaEventName, {
    ...segment,
    step_label: "benefit_add_complete",
    step_option: option,
  });
};

export const trackFailedOrder = (errorType: string, option?: string) => {
  track(eventName.Fail as TeliaEventName, {
    ...segment,
    error_message: "technical_error",
    error_type: errorType,
    step_option: option,
  });
};

export const trackBenefitRemoveInitiate = (option: string) => {
  track(eventName.RemoveInitiate as TeliaEventName, {
    ...segment,
    step_number: "1",
    step_label: "benefit_remove_initiate",
    step_option: option,
  });
};

export const trackBenefitRemoveProgress = (option: string, subOption?: string) => {
  track(eventName.RemoveProgress as TeliaEventName, {
    ...segment,
    step_number: "2",
    step_label: "benefit_remove_progress",
    step_option: option,
    step_option_sub: subOption,
  });
};

export const trackBenefitRemoveComplete = (option: string, subOption?: string) => {
  track(eventName.RemoveComplete as TeliaEventName, {
    ...segment,
    step_label: "benefit_remove_complete",
    step_option: option,
    step_option_sub: subOption,
  });
};

export const trackBenefitRemoveFail = (errorMessage: string) => {
  track(eventName.RemoveFail as TeliaEventName, {
    ...segment,
    step_label: "benefit_remove_fail",
    error_type: "benefit_remove_fail",
    error_message: errorMessage,
  });
};

export const trackSurveyInitiate = (action: string, text: string) => {
  track(eventName.SurveyInitiate as TeliaEventName, {
    ...segment,
    click_action: action,
    click_text: text,
  });
};
