<template>
  <div class="configure-databucket">
    <section class="content-wrapper">
      <telia-heading tag="h3" variant="subsection-100">{{ t("DATABUCKET.HEADER") }}</telia-heading>
      <telia-p>{{ t("BENEFIT_DESCRIPTION.business_plus_databucket") }}</telia-p>
    </section>

    <section class="button-wrapper">
      <div>
        <telia-button
          :text="t('ACTIVATE')"
          :ally-label="t('A11Y.ACTIVATE_BENEFIT')"
          t-id="submit-button"
          variant="primary"
          size="sm"
          full-width
          type="button"
          :disabled="disableOnSubmitAndSuccess || isTeliaAdmin"
          @click="addBenefit()"
        />
      </div>

      <div>
        <telia-button
          :text="t('CLOSE')"
          :ally-label="t('A11Y.CANCEL_ORDER')"
          variant="secondary"
          size="sm"
          full-width
          type="button"
          :disabled="isSubmitting"
          @click="closeModal()"
        />
      </div>
    </section>
  </div>
</template>

<script>
import { translateMixin } from "../../locale";
import { corpGoodiesService } from "@telia/b2b-rest-client";
import { trackAddBenefit } from "../../helper/benefitTracker";
import { trackSuccessfulOrder, trackFailedOrder } from "../../helper/analyticsManager";

export default {
  name: "configure-databucket",
  mixins: [translateMixin],
  props: {
    selectedSubscription: {
      type: Object,
      required: true,
    },
    scopeId: {
      type: String,
      required: true,
    },
    selectedTscid: {
      type: String,
      required: true,
    },
    isTeliaAdmin: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isSubmitting: false,
      status: null,
    };
  },
  computed: {
    disableOnSubmitAndSuccess() {
      return this.isSubmitting || this.status === "success";
    },
  },
  methods: {
    async addBenefit() {
      this.setSubmitState();

      const dataBucketSelection = this.selectedSubscription?.orderOnAssetSelection?.find(
        (el) => el.name === "business_plus_databucket"
      );

      const databucketPayload = {
        name: dataBucketSelection.name,
        parentAssetIdentifier: this.selectedSubscription.msisdn,
        serviceCode: "BPLUS",
        serviceValue: dataBucketSelection.serviceValue,
        productCategory: "mobilevoicesubscription",
      };

      try {
        const response = await corpGoodiesService.GoodiesControllerService.addBenefitUsingPost(
          databucketPayload,
          this.scopeId,
          this.selectedTscid
        );
        this.handleSubmitSuccess();
        trackAddBenefit("business_plus_databucket");
        trackSuccessfulOrder("business_plus_databucket");
        this.$emit("has-ordered", {
          type: "business_plus_databucket",
          orderNumber: response.orderNumber,
        });
      } catch (e) {
        this.handleSubmitError();
        trackFailedOrder("benefit_add_selected", "business_plus_databucket");
      } finally {
        this.$emit("set-status", this.status);
        this.isSubmitting = false;
        this.$emit("is-submitting", this.isSubmitting);
      }
    },
    setSubmitState() {
      this.isSubmitting = true;
      this.$emit("is-submitting", this.isSubmitting);
    },
    handleSubmitSuccess() {
      this.status = "success";
    },
    handleSubmitError() {
      this.status = "error";
    },
    closeModal() {
      this.$emit("close-modal");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables";

.content-wrapper {
  margin-bottom: $telia-spacing-24;
}

.button-wrapper {
  margin-bottom: $telia-spacing-24;
  div:first-of-type {
    margin-bottom: $telia-spacing-16;
  }
}
</style>
