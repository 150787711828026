<template>
  <div class="configure-withsecure">
    <section class="content-wrapper">
      <telia-heading tag="h3" variant="subsection-100">{{ t("WITHSECURE.HEADER") }}</telia-heading>
      <telia-p>{{ t("BENEFIT_DESCRIPTION.business_plus_withsecure") }}</telia-p>
    </section>

    <section class="withsecure-wrapper" v-if="email.isRequired">
      <div class="email-description">
        <telia-p>
          {{ t("WITHSECURE.EMAIL.DESCRIPTION") }}
        </telia-p>
      </div>

      <div>
        <telia-text-input
          :label="t('EMAIL')"
          :value="email.value"
          @input="email.value = $event.target.value"
          :pattern="email.repeatValue"
          required
          name="email"
          type="email"
          maxlength="50"
          :required-error-message="t('WITHSECURE.EMAIL.REQUIRED')"
          :email-error-message="t('WITHSECURE.EMAIL.INVALID')"
        />
      </div>
      <div>
        <telia-text-input
          :label="t('WITHSECURE.EMAIL.REPEAT_EMAIL')"
          :value="email.repeatValue"
          @input="email.repeatValue = $event.target.value"
          :disabled="!email.value"
          :pattern="email.value"
          required
          name="email"
          type="email"
          maxlength="50"
          :required-error-message="t('WITHSECURE.EMAIL.REQUIRED')"
          :email-error-message="t('WITHSECURE.EMAIL.INVALID')"
          :pattern-error-message="t('WITHSECURE.EMAIL.DOES_NOT_MATCH')"
        />
      </div>
    </section>

    <section class="button-wrapper">
      <div>
        <telia-button
          :text="t('ACTIVATE')"
          :ally-label="t('A11Y.ACTIVATE_BENEFIT')"
          t-id="submit-button"
          variant="primary"
          size="sm"
          full-width
          type="button"
          :disabled="disableOnSubmitAndSuccess || isTeliaAdmin || !isEmailValid"
          @click="addBenefit()"
        />
      </div>

      <div>
        <telia-button
          :text="t('CLOSE')"
          :ally-label="t('A11Y.CANCEL_ORDER')"
          variant="secondary"
          size="sm"
          full-width
          type="button"
          :disabled="isSubmitting"
          @click="closeModal()"
        />
      </div>
    </section>
  </div>
</template>

<script>
import { corpGoodiesService } from "@telia/b2b-rest-client";
import { translateMixin } from "../../locale";
import { trackAddBenefit } from "../../helper/benefitTracker";
import { trackSuccessfulOrder, trackFailedOrder } from "../../helper/analyticsManager";

export default {
  name: "configure-withsecure",
  mixins: [translateMixin],
  props: {
    selectedSubscription: {
      type: Object,
      required: true,
    },
    scopeId: {
      type: String,
      required: true,
    },
    selectedTscid: {
      type: String,
      required: true,
    },
    isTeliaAdmin: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isSubmitting: false,
      email: {
        value: "",
        repeatValue: "",
        isRequired: this.selectedSubscription.orderOnAssetSelection[0].emailRequired,
      },
      status: null,
    };
  },
  computed: {
    disableOnSubmitAndSuccess() {
      return this.isSubmitting || this.status === "success";
    },
    isEmailValid() {
      if (!this.email.isRequired) return true;
      return this.email.value && this.email.repeatValue && this.doesEmailsMatch;
    },
    doesEmailsMatch() {
      return this.email.value === this.email.repeatValue;
    },
  },
  methods: {
    async addBenefit() {
      this.setSubmitState();
      const withSecureSelection = this.selectedSubscription.orderOnAssetSelection.find(
        (el) => el.name === "business_plus_withsecure"
      );
      const withSecureRequest = {
        name: withSecureSelection.name,
        serviceValue: withSecureSelection.serviceValue,
        parentAssetIdentifier: this.selectedSubscription.msisdn,
        productCategory: "mobilevoicesubscription",
      };

      if (this.email.isRequired) {
        withSecureRequest["withSecureEmailAddress"] = this.email.value;
      }
      try {
        const response = await corpGoodiesService.GoodiesControllerService.addBenefitUsingPost(
          withSecureRequest,
          this.scopeId,
          this.selectedTscid
        );
        this.handleSubmitSuccess();
        trackAddBenefit("business_plus_withsecure");
        trackSuccessfulOrder("business_plus_withsecure");
        this.$emit("has-ordered", {
          type: "business_plus_withsecure",
          email: this.email.value,
          orderNumber: response.orderNumber,
        });
      } catch (e) {
        this.handleSubmitError();
        trackFailedOrder("benefit_add_selected", "business_plus_withsecure");
      } finally {
        this.$emit("set-status", this.status);
        this.isSubmitting = false;
        this.$emit("is-submitting", this.isSubmitting);
      }
    },

    setSubmitState() {
      this.isSubmitting = true;
      this.$emit("is-submitting", this.isSubmitting);
    },
    handleSubmitSuccess() {
      this.status = "success";
    },
    handleSubmitError() {
      this.status = "error";
    },
    closeModal() {
      this.$emit("close-modal");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables";

.content-wrapper {
  margin-bottom: $telia-spacing-24;
}

.withsecure-wrapper > div {
  margin-bottom: $telia-spacing-24;
}

.button-wrapper {
  margin-bottom: $telia-spacing-24;
  div:first-of-type {
    margin-bottom: $telia-spacing-16;
  }
}
</style>
