<template>
  <b2x-onboarding :is-open="open" @closeModal="onCloseModal">
    <b2x-onboarding-step
      v-for="(step, index) in steps"
      :key="index"
      :src="step.src"
      :heading="step.heading"
      :description="step.description"
    ></b2x-onboarding-step>
  </b2x-onboarding>
</template>

<script>
import { translateMixin } from "../locale";
import BENEFIT_OVERVIEW from "../assets/BENEFIT_OVERVIEW.gif";
import BENEFIT_ADD from "../assets/BENEFIT_ADD.gif";
import BENEFIT_DELETE from "../assets/BENEFIT_DELETE.gif";

export default {
  name: "guide-modal",
  mixins: [translateMixin],
  model: {
    prop: "open",
    event: "change",
  },
  props: {
    open: { type: Boolean, default: false },
  },
  computed: {
    steps() {
      return [
        {
          src: BENEFIT_OVERVIEW,
          heading: this.t("GUIDE.CHOOSE_SUBSCRIPTION.TITLE"),
          description: this.t("GUIDE.CHOOSE_SUBSCRIPTION.DESCRIPTION"),
        },
        {
          src: BENEFIT_ADD,
          heading: this.t("GUIDE.CHOOSE_BENEFIT.TITLE"),
          description: this.t("GUIDE.CHOOSE_BENEFIT.DESCRIPTION"),
        },
        {
          src: BENEFIT_DELETE,
          heading: this.t("GUIDE.REMOVE_BENEFIT.TITLE"),
          description: this.t("GUIDE.REMOVE_BENEFIT.DESCRIPTION"),
        },
      ];
    },
  },
  methods: {
    onCloseModal() {
      this.$emit("change", false);
    },
  },
};
</script>
