<template>
  <section v-if="status">
    <telia-notification
      :status="status"
      :button-text="closeText"
      @vocaClose="onClose"
      heading-tag="h4"
    >
      <span slot="heading">
        <telia-visually-hidden>{{ a11yMessage }}</telia-visually-hidden>
        <strong>{{ heading }}</strong>
      </span>
      <span slot="content">
        <telia-p>{{ message }}</telia-p>
      </span>
    </telia-notification>
  </section>
</template>

<script>
import { translateMixin } from "../locale";
export default {
  name: "notifications",
  mixins: [translateMixin],
  props: {
    status: {
      type: String,
      default: () => "",
    },
    heading: {
      type: String,
      default: () => "",
    },
    message: {
      type: String,
      default: () => "",
    },
    a11yMessage: {
      type: String,
      default: () => "",
    },
    closeText: {
      type: String,
      default: () => "",
    },
  },
  methods: {
    onClose() {
      this.$emit("close");
    },
  },
};
</script>
