import * as analytics from "@telia/b2b-web-analytics-wrapper";
const { action } = analytics;

export const eventAction = action;

const BENEFITS = "Förmåner";
const BENEFIT_CONFIG = "förmåner_config";

export function trackBenefitsEvent(action: string, label: string): void {
  analytics.trackEvent(BENEFITS, action, label);
}
export function trackBenefitsConfigEvent(action: string, label: string): void {
  analytics.trackEvent(BENEFIT_CONFIG, action, label);
}

let timeout: number | undefined;
function delayedTracking(delay: number, action: string, label: string): void {
  if (timeout !== undefined) {
    window.clearTimeout(timeout);
  }
  timeout = window.setTimeout(() => {
    trackBenefitsEvent(action, label);
  }, delay);
}

export function trackColumnSearch(column: string): void {
  delayedTracking(1000, analytics.action.FILTER, column);
}

export function trackAddBenefit(benefit: string): void {
  trackBenefitsConfigEvent(action.COMPLETED, benefit);
}

type DataSimFormat = "simcard" | "esim";
export function trackSimFormat(simFormat: DataSimFormat): void {
  trackBenefitsConfigEvent(
    "Delivery",
    `${simFormat === "simcard" ? "business_plus_datasim_simcard" : "business_plus_datasim_e-sim"}`
  );
}

export function trackSurveyClick(): void {
  analytics.trackEvent(BENEFITS, analytics.action.CLICK, "survey");
}
